/**
 * Datart
 *
 * Copyright 2021
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { ChartDataSectionType } from 'app/constants';
import { ChartDrillOption } from 'app/models/ChartDrillOption';
import {
  ChartConfig,
  ChartDataConfig,
  ChartDataSectionField,
  ChartStyleConfig,
  LabelStyle,
  LegendStyle,
  XAxis,
  XAxisColumns,
  YAxis,
} from 'app/types/ChartConfig';
import ChartDataSetDTO, { IChartDataSet } from 'app/types/ChartDataSet';
import {
  getColorizeGroupSeriesColumns,
  getColumnRenderName,
  getDrillableRows,
  getExtraSeriesDataFormat,
  getExtraSeriesRowData,
  getGridStyle,
  getReference2,
  getSeriesTooltips4Rectangular2,
  getStyles,
  hadAxisLabelOverflowConfig,
  setOptionsByAxisLabelOverflow,
  toFormattedValue,
  transformToDataSet,
} from 'app/utils/chartHelper';
import { toPrecision } from 'app/utils/number';
import { init } from 'echarts';
import { UniqArray } from 'utils/object';
import Chart from '../../../models/Chart';
import { ChartRequirement } from '../../../types/ChartMetadata';
import Config from './config';
import { BarBorderStyle, BarSeriesImpl, Series } from './types';

class BasicBarChart extends Chart {
  config = Config;
  chart: any = null;

  protected isHorizonDisplay = false;
  protected isStackMode = false;
  protected isPercentageYAxis = false;

  constructor(props?: {
    id: string;
    name: string;
    icon: string;
    requirements?: ChartRequirement[];
  }) {
    super(
      props?.id || 'bar',
      props?.name || 'viz.palette.graph.names.barChart',
      props?.icon || 'chart-bar',
    );
    this.meta.requirements = props?.requirements || [
      {
        group: [0, 1],
        aggregate: [1, 999],
      },
    ];
  }

  onMount(options, context): void {
    if (options.containerId === undefined || !context.document) {
      return;
    }

    this.chart = init(
      context.document.getElementById(options.containerId),
      'default',
    );
    this.mouseEvents?.forEach(event => {
      this.chart.on(event.name, event.callback);
    });
  }

  onUpdated(options, context): void {
    if (!options.dataset || !options.dataset.columns || !options.config) {
      return;
    }
    if (!this.isMatchRequirement(options.config)) {
      this.chart?.clear();
      return;
    }
    const newOptions = this.getOptions(
      options.dataset,
      options.config,
      options.drillOption,
    );
    this.chart?.setOption(Object.assign({}, newOptions), true);
  }

  onUnMount(): void {
    this.chart?.dispose();
  }

  onResize(opt: any, context): void {
    this.chart?.resize({ width: context?.width, height: context?.height });
    hadAxisLabelOverflowConfig(this.chart?.getOption()) &&
      this.onUpdated(opt, context);
  }

  getOptions(
    dataset: ChartDataSetDTO,
    config: ChartConfig,
    drillOption: ChartDrillOption,
  ) {
    // console.log("dataset:", JSON.stringify(dataset));
    // console.log("config:", JSON.stringify(config));
    const styleConfigs: ChartStyleConfig[] = config.styles || [];
    const dataConfigs: ChartDataConfig[] = config.datas || [];
    const settingConfigs: ChartStyleConfig[] = config.settings || [];

    const groupConfigs: ChartDataSectionField[] = getDrillableRows(
      dataConfigs,
      drillOption,
    );
    const aggregateConfigs: ChartDataSectionField[] = dataConfigs
      .filter(c => c.type === ChartDataSectionType.AGGREGATE)
      .flatMap(config => config.rows || []);
    const colorConfigs: ChartDataSectionField[] = dataConfigs
      .filter(c => c.type === ChartDataSectionType.COLOR)
      .flatMap(config => config.rows || []);
    const infoConfigs: ChartDataSectionField[] = dataConfigs
      .filter(c => c.type === ChartDataSectionType.INFO)
      .flatMap(config => config.rows || []);

    const chartDataSet = transformToDataSet(
      dataset.rows,
      dataset.columns,
      dataConfigs,
    );

    if (this.isHorizonDisplay) {
      chartDataSet.reverse();
    }

    let [xAxisOffset] = getStyles(styleConfigs,['xAxis'],["offset"]);

    const xAxisColumns: XAxisColumns[] = [
      {
        type: 'category',
        tooltip: { show: true },
        data: UniqArray(
          chartDataSet?.map(row => {
            return groupConfigs.map(g => row.getCell(g)).join('-');
          }),
        ),
        offset: xAxisOffset
      },
    ];

    const yAxisNames: string[] = aggregateConfigs.map(getColumnRenderName);
    const series = this.getSeries(
      settingConfigs,
      styleConfigs,
      colorConfigs,
      chartDataSet,
      groupConfigs,
      aggregateConfigs,
      infoConfigs,
      xAxisColumns,
    );

    const axisInfo = {
      xAxis: this.getXAxis(styleConfigs, xAxisColumns),
      yAxis: this.getYAxis(styleConfigs, yAxisNames),
    };

    if (this.isStackMode) {
      this.makeStackSeries(styleConfigs, series);
    }
    if (this.isPercentageYAxis) {
      this.makePercentageSeries(styleConfigs, series);
      this.makePercentageYAxis(axisInfo);
    }
    if (this.isHorizonDisplay) {
      this.makeTransposeAxis(axisInfo);
    }

    // @TM 溢出自动根据bar长度设置标尺
    const option = setOptionsByAxisLabelOverflow({
      chart: this.chart,
      xAxis: axisInfo.xAxis,
      yAxis: axisInfo.yAxis,
      grid: getGridStyle(styleConfigs),
      yAxisNames,
      series,
      horizon: this.isHorizonDisplay,
    });

    let [tooltipFont] = getStyles(styleConfigs,['label'],["tooltipFont"]);
    let [tooltipBackgroundColor] = getStyles(styleConfigs,['label'],["backgroundColor"]);
    let [titleText, titleLeft, titleTop, titleFont] = getStyles(styleConfigs,['title'],["text", "left", "top", "font"]);

    const chartOptions = {
      tooltip: {
        backgroundColor: tooltipBackgroundColor,
        trigger: 'item',
        textStyle: {
          ...tooltipFont
        },
        formatter: this.getTooltipFormatterFunc(
          chartDataSet,
          groupConfigs,
          aggregateConfigs,
          colorConfigs,
          infoConfigs,
        ),
      },
      legend: this.getLegendStyle(styleConfigs, series),
      ...option,
    };

    if(titleText) { // 有标题内容才把标题相关的配置引入
      chartOptions['title'] = {
        text : titleText,
        left: titleLeft,
        top : titleTop,
        textStyle: {
          ...titleFont
        }
      }
    }

    // console.log("chartOptions:" + JSON.stringify(chartOptions));
    return chartOptions;
  }

  private makePercentageYAxis(axisInfo: { xAxis: XAxis; yAxis: YAxis }) {
    if (axisInfo.yAxis) {
      axisInfo.yAxis.min = 0;
      axisInfo.yAxis.max = 100;
    }
  }

  private makeTransposeAxis(info) {
    const temp = info.xAxis;
    info.xAxis = info.yAxis;
    info.yAxis = temp;
  }

  private getSeries(
    settingConfigs: ChartStyleConfig[],
    styleConfigs: ChartStyleConfig[],
    colorConfigs: ChartDataSectionField[],
    chartDataSet: IChartDataSet<string>,
    groupConfigs: ChartDataSectionField[],
    aggregateConfigs: ChartDataSectionField[],
    infoConfigs: ChartDataSectionField[],
    xAxisColumns: XAxisColumns[],
  ): Series[] {
    let [useColorFunc,colorFunc] = getStyles(styleConfigs,['bar'],["useColorFunc", "colorFunc"]);
    // console.log("styleConfigs", styleConfigs);
    // console.log("useColorFunc", useColorFunc);
    // console.log("colorFunc", colorFunc);

    if (!colorConfigs.length) {
      return aggregateConfigs.map(aggConfig => {
        let result = {
          ...this.getBarSeriesImpl(
            styleConfigs,
            settingConfigs,
            chartDataSet,
            aggConfig,
          ),
          name: getColumnRenderName(aggConfig),
          data: chartDataSet?.map(dc => ({
            ...getExtraSeriesRowData(dc),
            ...getExtraSeriesDataFormat(aggConfig?.format),
            name: getColumnRenderName(aggConfig),
            value: dc.getCell(aggConfig),
          }))
        };

        if(useColorFunc) {
          result['itemStyle'] = {
            color: function (params) {
              return new Function('params', colorFunc)(params);
            },
          };
        }

        // console.log("result1", result)
        return result;
      });
    }


    const secondGroupInfos = getColorizeGroupSeriesColumns(
      chartDataSet,
      colorConfigs[0],
    );

    if(colorConfigs[0]?.notAggregateField) {
      // 支持按字段来直接设置柱状图颜色，而不是聚合的方式。
      return  aggregateConfigs.map(aggConfig => {
        let result = {
          ...this.getBarSeriesImpl(
            styleConfigs,
            settingConfigs,
            chartDataSet,
            aggConfig,
          ),
          name: getColumnRenderName(aggConfig),
          data: chartDataSet?.map(dc => ({
            ...getExtraSeriesRowData(dc),
            ...getExtraSeriesDataFormat(aggConfig?.format),
            name: getColumnRenderName(aggConfig),
            value: dc.getCell(aggConfig),
            itemStyle: {
              color: useColorFunc ? undefined : colorConfigs?.[0]?.color?.colors?.find(
                c => {
                  return c.key === getExtraSeriesRowData(dc).rowData[colorConfigs?.[0].colName];
                },
              )?.value,
            },
          }))
        };

        if(useColorFunc) {
          result['itemStyle'] = {
            color: function (params) {
              return new Function('params', colorFunc)(params);
            },
          };
        }

        // console.log('result2', result);
        return result;
      });

    }


    const colorizeGroupedSeries = aggregateConfigs.flatMap(aggConfig => {
      return secondGroupInfos.map(sgCol => {
        const k = Object.keys(sgCol)[0];
        const dataSet = sgCol[k];

        const itemStyleColor = colorConfigs?.[0]?.color?.colors?.find(
          c => c.key === k,
        );

        return {
          ...this.getBarSeriesImpl(
            styleConfigs,
            settingConfigs,
            chartDataSet,
            aggConfig,
          ),
          name: k,
          data: xAxisColumns?.[0]?.data?.map(d => {
            const row = dataSet.find(
              r => r.getMultiCell(...groupConfigs) === d,
            )!;
            return {
              ...getExtraSeriesRowData(row),
              ...getExtraSeriesDataFormat(aggConfig?.format),
              name: getColumnRenderName(aggConfig),
              value: row?.getCell(aggConfig),
            };
          }),
          itemStyle: this.getSeriesItemStyle(styleConfigs, {
            color: useColorFunc ? function (params) {
              return new Function('params', colorFunc)(params);
            } : itemStyleColor?.value,
          }),
        };
      });
    });
    // console.log("colorizeGroupedSeries:", colorizeGroupedSeries);
    return colorizeGroupedSeries;
  }

  private getBarSeriesImpl(
    styleConfigs: ChartStyleConfig[],
    settingConfigs: ChartStyleConfig[],
    chartDataSet: IChartDataSet<string>,
    dataConfig: ChartDataSectionField,
  ): BarSeriesImpl {
    return {
      type: 'bar',
      sampling: 'average',
      barGap: this.getSeriesBarGap(styleConfigs),
      barWidth: this.getSeriesBarWidth(styleConfigs),
      itemStyle: this.getSeriesItemStyle(styleConfigs, {
        color: dataConfig?.color?.start,
      }),
      ...this.getLabelStyle(styleConfigs),
      ...this.getSeriesStyle(styleConfigs),
      ...getReference2(
        settingConfigs,
        chartDataSet,
        dataConfig,
        this.isHorizonDisplay,
      ),
    };
  }

  private makeStackSeries(_: ChartStyleConfig[], series: Series[]): Series[] {
    (series || []).forEach(s => {
      s['stack'] = this.isStackMode ? this.getStackName(1) : undefined;
    });
    return series;
  }

  private makePercentageSeries(
    styles: ChartStyleConfig[],
    series: Series[],
  ): Series[] {
    const _getAbsValue = data => {
      if (typeof data === 'object' && data !== null && 'value' in data) {
        return Math.abs(data.value || 0);
      }
      return data;
    };

    const _convertToPercentage = (data, totalArray: number[]) => {
      return (data || []).map((d, dataIndex) => {
        const sum = totalArray[dataIndex];
        const percentageValue = toPrecision((_getAbsValue(d) / sum) * 100, 0);
        return {
          ...d,
          value: percentageValue,
          total: sum,
        };
      });
    };

    const _seriesTotalArrayByDataIndex = (series?.[0]?.data || []).map(
      (_, index) => {
        const sum = series.reduce((acc, cur) => {
          const value = +_getAbsValue(cur.data?.[index] || 0);
          acc = acc + value;
          return acc;
        }, 0);
        return sum;
      },
    );
    (series || []).forEach(s => {
      s.data = _convertToPercentage(s.data, _seriesTotalArrayByDataIndex);
    });
    return series;
  }

  private getSeriesItemStyle(
    styles: ChartStyleConfig[],
    itemStyle?: { color: any },
  ): BarBorderStyle {
    const [borderStyle, borderRadius] = getStyles(
      styles,
      ['bar'],
      ['borderStyle', 'radius'],
    );

    return {
      ...itemStyle,
      borderRadius,
      borderType: borderStyle?.type,
      borderWidth: borderStyle?.width,
      borderColor: borderStyle?.color,
    };
  }

  private getSeriesBarGap(styles: ChartStyleConfig[]): number {
    const [gap] = getStyles(styles, ['bar'], ['gap']);
    return gap;
  }

  private getSeriesBarWidth(styles: ChartStyleConfig[]): number {
    const [width] = getStyles(styles, ['bar'], ['width']);
    return width;
  }

  private getYAxis(styles: ChartStyleConfig[], yAxisNames: string[]): YAxis {
    let [
      showAxis,
      inverse,
      lineStyle,
      showLabel,
      font,
      showTitleAndUnit,
      unitFont,
      nameLocation,
      nameGap,
      nameRotate,
      min,
      max,
      formatter,
      axisLabelWidth,
      axisLabelMargin
    ] = getStyles(
      styles,
      ['yAxis'],
      [
        'showAxis',
        'inverseAxis',
        'lineStyle',
        'showLabel',
        'font',
        'showTitleAndUnit',
        'unitFont',
        'nameLocation',
        'nameGap',
        'nameRotate',
        'min',
        'max',
        "formatter",
        'axisLabelWidth',
        "axisLabel.margin"
      ],
    );

    const name = showTitleAndUnit ? yAxisNames.join(' / ') : null;
    const [showHorizonLine, horizonLineStyle] = getStyles(
      styles,
      ['splitLine'],
      ['showHorizonLine', 'horizonLineStyle'],
    );

    if(formatter && formatter.startsWith("return")) {
      formatter = new Function('value','index', formatter);
    }

    return {
      type: 'value',
      name,
      nameLocation,
      nameGap,
      nameRotate,
      inverse,
      min,
      max,
      axisLabel: {
        show: showLabel,
        formatter,
        ...font,
        width: axisLabelWidth,
        margin: axisLabelMargin ?? 8,
      },
      axisLine: {
        show: showAxis,
        lineStyle,
      },
      axisTick: {
        show: showLabel,
        lineStyle,
      },
      nameTextStyle: unitFont,
      splitLine: {
        show: showHorizonLine,
        lineStyle: horizonLineStyle,
      },
    };
  }

  private getXAxis(
    styles: ChartStyleConfig[],
    xAxisColumns: XAxisColumns[],
  ): XAxis {
    const axisColumnInfo = xAxisColumns[0];
    let [
      showAxis,
      inverse,
      lineStyle,
      showLabel,
      font,
      rotate,
      showInterval,
      interval,
      overflow,
      formatter,
      axisLabelWidth,
      xAxisMargin
    ] = getStyles(
      styles,
      ['xAxis'],
      [
        'showAxis',
        'inverseAxis',
        'lineStyle',
        'showLabel',
        'font',
        'rotate',
        'showInterval',
        'interval',
        'overflow',
        'formatter',
        'axisLabelWidth',
        'xAxisMargin'
      ],
    );
    const [showVerticalLine, verticalLineStyle] = getStyles(
      styles,
      ['splitLine'],
      ['showVerticalLine', 'verticalLineStyle'],
    );

    if(formatter && formatter.startsWith("return")) {
      formatter = new Function('value','index', formatter);
    }

    return {
      ...axisColumnInfo,
      inverse,
      axisLabel: {
        show: showLabel,
        rotate,
        interval: showInterval ? interval : 'auto',
        overflow,
        ...font,
        formatter,
        width: axisLabelWidth,
        margin: xAxisMargin || 0
      },
      axisLine: {
        show: showAxis,
        lineStyle,
      },
      axisTick: {
        show: showLabel,
        lineStyle,
      },
      splitLine: {
        show: showVerticalLine,
        lineStyle: verticalLineStyle,
      }
    };
  }

  private getLegendStyle(styles, series): LegendStyle {
    const seriesNames: string[] = (series || []).map((col: any) => col?.name);
    const [show, type, font, legendPos, selectAll, height, left] = getStyles(
      styles,
      ['legend'],
      ['showLegend', 'type', 'font', 'position', 'selectAll', 'height', 'left'],
    );
    let positions: {
      width?: number;
      height?: number;
      top?: number | string;
      left?: number | string;
      right?: number;
      bottom?: number;
    } = {};
    let orient = '';

    let leftVal = left ? (left === 'left' ? 18 : left) : 8;

    switch (legendPos) {
      case 'top':
        orient = 'horizontal';
        positions = { top: 8, left: leftVal, right: 8, height: 32 };
        break;
      case 'bottom':
        orient = 'horizontal';
        positions = { bottom: 8, left: leftVal, right: 8, height: 32 };
        break;
      case 'left':
        orient = 'vertical';
        positions = { left: 8, top: leftVal, bottom: 24, width: 96 };
        break;
      default:
        orient = 'vertical';
        positions = { right: 8, top: leftVal, bottom: 24, width: 96 };
        break;
    }
    const selected: { [x: string]: boolean } = seriesNames.reduce(
      (obj, name) => ({
        ...obj,
        [name]: selectAll,
      }),
      {},
    );


    return {
      ...positions,
      show,
      type,
      height: height || null,
      orient,
      selected,
      data: seriesNames,
      textStyle: font,
    };
  }

  private getLabelStyle(styles): LabelStyle {
    const [show, position, font] = getStyles(
      styles,
      ['label'],
      ['showLabel', 'position', 'font'],
    );

    return {
      label: {
        show,
        position,
        ...font,
        formatter: params => {
          const { value, data } = params;
          if (!value || !Number(value)) {
            return '';
          }
          let formattedValue = toFormattedValue(value, data.format);
          if (this.isPercentageYAxis) {
            formattedValue = value + '%';
          }
          const labels: string[] = [];
          labels.push(formattedValue);
          return labels.join('\n');
        },
      },
      labelLayout: { hideOverlap: true },
    };
  }

  private getSeriesStyle(styles): { smooth: boolean; step: boolean } {
    const [smooth, step] = getStyles(styles, ['graph'], ['smooth', 'step']);
    return { smooth, step };
  }

  private getStackName(index): string {
    return `total`;
  }

  private getTooltipFormatterFunc(
    chartDataSet: IChartDataSet<string>,
    groupConfigs: ChartDataSectionField[],
    aggregateConfigs: ChartDataSectionField[],
    colorConfigs: ChartDataSectionField[],
    infoConfigs: ChartDataSectionField[],
  ): (params) => string {
    return seriesParams => {
      const params = Array.isArray(seriesParams)
        ? seriesParams
        : [seriesParams];
      return getSeriesTooltips4Rectangular2(
        chartDataSet,
        params[0],
        groupConfigs,
        colorConfigs,
        aggregateConfigs,
        infoConfigs,
      );
    };
  }
}

export default BasicBarChart;
