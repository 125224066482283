/**
 * Datart
 *
 * Copyright 2021
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {ChartDataSectionType} from 'app/constants';
import {ChartConfig, ChartDataSectionField, ChartStyleConfig,} from 'app/types/ChartConfig';
import ChartDataSetDTO, {IChartDataSet} from 'app/types/ChartDataSet';
import {
  getDataColumnMaxAndMin2,
  getExtraSeriesRowData,
  getScatterSymbolSizeFn,
  getSeriesTooltips4Polar2,
  getStyles,
  toFormattedValue,
  transformToDataSet,
} from 'app/utils/chartHelper';
import {init, registerMap} from 'echarts';
import Chart from '../../../models/Chart';
import Config from './config';
import geoChinaCity from './geo-china-city.map.json';
import geoChinaCompany from './geo-china-company.map.json';
import geoChinaCompanyBoss from './geo-china-company-boss.map.json';
import geoChinaCompanyBoss2022 from './geo-china-company-boss2022.map.json';
import geoChina from './geo-china.map.json';
import geoChinaWorld from './geo-world.map.json';
import {GeoInfo, GeoSeries, GeoVisualMapStyle, MetricAndSizeSeriesStyle,} from './types';

// NOTE: source from: http://datav.aliyun.com/tools/atlas/index.html#&lat=31.39115752282472&lng=103.7548828125&zoom=4
registerMap('china', geoChina as any);
registerMap('china-city', geoChinaCity as any);
registerMap('china-company', geoChinaCompany as any);
registerMap('world-china', geoChinaWorld as any);
registerMap('world-china-company', geoChinaWorld as any);

class BasicOutlineMapChart extends Chart {
  chart: any = null;
  config = Config;
  protected isNormalGeoMap = false;
  private geoMap;

  constructor(props?) {
    super(
      props?.id || 'basic-outline-map',
      props?.name || 'viz.palette.graph.names.outlineMap',
      props?.icon || 'china',
    );
    this.meta.requirements = props?.requirements || [
      {
        group: 1,
        aggregate: 1,
      },
    ];
  }

  onMount(options, context): void {
    if (options.containerId === undefined || !context.document) {
      return;
    }

    this.chart = init(
      context.document.getElementById(options.containerId),
      'default',
    );
    this.mouseEvents?.forEach(event => {
      this.chart.on(event.name, event.callback);
    });
  }

  onUpdated(props): void {
    if (!props.dataset || !props.dataset.columns || !props.config) {
      return;
    }
    if (!this.isMatchRequirement(props.config)) {
      this.chart?.clear();
      return;
    }
    const newOptions = this.getOptions(props.dataset, props.config);
    this.chart?.setOption(Object.assign({}, newOptions), true);
  }

  onUnMount(): void {
    this.chart?.dispose();
  }

  onResize(opt: any, context): void {
    this.chart?.resize(context);
  }

  private getOptions(dataset: ChartDataSetDTO, config: ChartConfig) {
    const styleConfigs = config.styles || [];
    const dataConfigs = config.datas || [];
    const groupConfigs = dataConfigs
      .filter(c => c.type === ChartDataSectionType.GROUP)
      .flatMap(config => config.rows || []);
    const aggregateConfigs = dataConfigs
      .filter(c => c.type === ChartDataSectionType.AGGREGATE)
      .flatMap(config => config.rows || []);
    const sizeConfigs = dataConfigs
      .filter(c => c.type === ChartDataSectionType.SIZE)
      .flatMap(config => config.rows || []);
    const infoConfigs = dataConfigs
      .filter(c => c.type === ChartDataSectionType.INFO)
      .flatMap(config => config.rows || []);

    this.registerGeoMap(styleConfigs);

    const chartDataSet = transformToDataSet(
      dataset.rows,
      dataset.columns,
      dataConfigs,
    );

    let visualMap = this.getVisualMap(
        chartDataSet,
        groupConfigs,
        aggregateConfigs,
        sizeConfigs,
        styleConfigs,
    );

    const [type] = getStyles(styleConfigs, ['visualMap'],['type']);
    // 暂时先针对老板看板做特殊处理，后面看怎么通用化
    let isBossReport = type == 'piecewiseBoss';

    const geo = this.getGeoInfo(
        chartDataSet,
        groupConfigs,
        aggregateConfigs,
        sizeConfigs,
        styleConfigs,
        infoConfigs,);

    console.log("isBossReport:", isBossReport);

    const mapOptions = {
      geo: isBossReport ? undefined : geo,
      visualMap: visualMap,
      series: this.getGeoSeries(
        chartDataSet,
        groupConfigs,
        aggregateConfigs,
        sizeConfigs,
        styleConfigs,
        isBossReport,
      ),
      tooltip: this.getTooltip(
        chartDataSet,
        groupConfigs,
        aggregateConfigs,
        sizeConfigs,
        styleConfigs,
        infoConfigs,
      ),
    };

    // console.log("mapOptions:" + JSON.stringify(mapOptions));
    return mapOptions;
  }

  private registerGeoMap(styleConfigs: ChartStyleConfig[]) {
    const [mapLevelName] = getStyles(styleConfigs, ['map'], ['level']);
    switch (mapLevelName) {
      case 'china':
        this.geoMap = geoChina;
        break;
      case 'china-company':
        this.geoMap = geoChinaCompany;
        break;
      case 'china-city':
        this.geoMap = geoChinaCity;
        break;
      case 'world-china':
        geoChinaWorld.features = geoChinaWorld.features.concat(
          geoChinaCompanyBoss.features as Array<any>,
        );
        let worldAndChina = Object.assign({}, geoChinaWorld); // 原本的world还需要用 所以这里用了一个深拷贝赋值世界地图数据
        this.geoMap = worldAndChina;
        break;
      case 'world-china-company':
        geoChinaWorld.features = geoChinaWorld.features.concat(
          geoChinaCompanyBoss2022.features as Array<any>,
        );
        let worldAndChinaCompany = Object.assign({}, geoChinaWorld); // 原本的world还需要用 所以这里用了一个深拷贝赋值世界地图数据
        this.geoMap = worldAndChinaCompany;
        break;
      default:
        this.geoMap = geoChina;
        break;
    }
  }

  private getGeoInfo(chartDataSet: IChartDataSet<string>,
                      groupConfigs: ChartDataSectionField[],
                      aggregateConfigs: ChartDataSectionField[],
                      sizeConfigs: ChartDataSectionField[],
                      styleConfigs: ChartStyleConfig[],
                     infoConfigs: ChartDataSectionField[],): GeoInfo {
    const [show, position, font] = getStyles(
      styleConfigs,
      ['label'],
      ['showLabel', 'position', 'font'],
    );
    const [
      mapLevelName,
      enableZoom,
      areaColor,
      areaEmphasisColor,
      enableFocus,
      borderStyle,
      zoomLevel,
      centerPos,
      showNoDataRegions
    ] = getStyles(
      styleConfigs,
      ['map'],
      [
        'level',
        'enableZoom',
        'areaColor',
        'areaEmphasisColor',
        'focusArea',
        'borderStyle',
        'zoomLevel',
        'centerPos',
        'showNoDataRegions'
      ],
    );
    // const zoom = mapLevelName === 'world-china' ? 4.0 : 1;
    let [centerX, centerY] = centerPos.split(',');
    centerX = centerX.trim()
    centerY = centerY.trim()

    // console.log("show:" + show);
    // 只针对有数据的区域进行显示
    let regions = chartDataSet
      ?.map(row => {
        return {
          name: this.mappingGeoName(row.getCell(groupConfigs[0])),
          label: {
            show, position, ...font
          },
          emphasis: {
            focus: enableFocus ? 'self' : 'none',
            itemStyle: {
              areaColor: areaEmphasisColor,
            },
            label: {
              show,
            },
          },
          visualMap: show,
        };
      })?.filter(d => !!d.name);

    return {
      map: mapLevelName,
      roam: enableZoom,
      zoom: zoomLevel,
      center: [centerX, centerY],
      emphasis: {
        focus: enableFocus ? 'self' : 'none',
        itemStyle: {
          areaColor: !!showNoDataRegions ? areaEmphasisColor : areaColor,
        },
        label: {
          show: !!showNoDataRegions && show
        }
      },
      itemStyle: {
        areaColor: areaColor,
        borderType: borderStyle?.type,
        borderWidth: borderStyle?.width,
        borderColor: borderStyle?.color,
      },
      select: {
        disabled: true
      },
      label: {
        show: !!showNoDataRegions && show,
        position, ...font
      },
      regions: regions,
      labelLayout: { hideOverlap: true },
    };
  }

  protected getGeoSeries(
    chartDataSet: IChartDataSet<string>,
    groupConfigs: ChartDataSectionField[],
    aggregateConfigs: ChartDataSectionField[],
    sizeConfigs: ChartDataSectionField[],
    styleConfigs: ChartStyleConfig[],
    isBossReport: boolean
  ): GeoSeries[] {
    const [showLabel, position, font] = getStyles(
        styleConfigs,
        ['label'],
        ['showLabel', 'position', 'font'],
    );
    const [show] = getStyles(styleConfigs, ['visualMap'], ['show']);
    const [
      mapLevelName,
      enableZoom,
      areaColor,
      areaEmphasisColor,
      enableFocus,
      borderStyle,
      zoomLevel,
      centerPos,
      showNoDataRegions
    ] = getStyles(
        styleConfigs,
        ['map'],
        [
          'level',
          'enableZoom',
          'areaColor',
          'areaEmphasisColor',
          'focusArea',
          'borderStyle',
          'zoomLevel',
          'centerPos',
          'showNoDataRegions'
        ],
    );

    // console.log("chartDataSet:" + JSON.stringify(chartDataSet));

    // const zoom = mapLevelName === 'world-china' ? 4.0 : 1;
    let [centerX, centerY] = centerPos.split(',');
    centerX = centerX.trim()
    centerY = centerY.trim()

    let _enableFocus = enableFocus;
    let _showToolTip = true;

    return [
      {
        type: 'map',
        roam: !!enableZoom,
        map: mapLevelName,
        zoom: isBossReport ? zoomLevel + 5.5 : undefined,
        center: isBossReport ? [centerX, centerY] : undefined,
        selectedMode: 'false',
        right: '5%',
        geoIndex: 0,
        emphasis: {
          focus: enableFocus ? 'self' : 'none',
          label: {
            show: false,
          },
          itemStyle: {
            areaColor: isBossReport ? '#121F44' : undefined,
          },
        },

        itemStyle: {
          areaColor: isBossReport ? '#0a1c2b' : undefined,
        },
        data: chartDataSet
          ?.map(row => {
            let value = 0;
            let _areaColor = areaEmphasisColor;
            let isBossReportAndTaiwan = false;

            if (isBossReport) {
              value = Number(row['1']);
              // _areaColor = value ? (value < 0.9 ? '#DF4153' : (value < 0.98 ? '#FF8805': '#4D569C')) : '#DF4153';
              _areaColor = value ? (value < 0.9 ? '#661c25' : (value < 0.98 ? '#c76901': '#0417a8')) : '#DF4153';

              if (row.getCell(groupConfigs[0]) === '台湾') {
                _showToolTip = false;
                isBossReportAndTaiwan = true;
                _areaColor = '#495396';
                _enableFocus = false;
              }
            }

            let _emphasisLabelColor = isBossReport ? 'white': undefined;

            return {
              ...getExtraSeriesRowData(row),
              itemStyle: {
                color: isBossReportAndTaiwan ? '#495396' : undefined,
              },
              label: {
                show: showLabel && !isBossReportAndTaiwan, position, ...font
              },
              tooltip: {
                show: _showToolTip
              },
              emphasis: {
                focus: _enableFocus ? 'self' : 'none',
                itemStyle: {
                  areaColor: _areaColor,
                },
                label: {
                  show: showLabel,
                  color: _emphasisLabelColor
                },
              },
              name: this.mappingGeoName(row.getCell(groupConfigs[0])),
              value: row.getCell(aggregateConfigs[0]),
              visualMap: show,
            };
          })?.filter(d => !!d.name && d.value !== undefined),
      },
    ];
  }

  protected getMetricAndSizeSeries(
    chartDataSet: IChartDataSet<string>,
    groupConfigs: ChartDataSectionField[],
    aggregateConfigs: ChartDataSectionField[],
    sizeConfigs: ChartDataSectionField[],
    styleConfigs: ChartStyleConfig[],
  ): MetricAndSizeSeriesStyle[] {
    if (this.isNormalGeoMap) {
      return [];
    }

    const [showLabel] = getStyles(styleConfigs, ['label'], ['showLabel']);
    const [cycleRatio] = getStyles(styleConfigs, ['map'], ['cycleRatio']);
    const { min, max } = getDataColumnMaxAndMin2(chartDataSet, sizeConfigs[0]);
    const defaultSizeValue = (max - min) / 2;
    const defaultColorValue = 1;

    return [
      {
        type: 'scatter',
        zlevel: 2,
        coordinateSystem: 'geo',
        symbol: 'circle',
        data: chartDataSet
          ?.map(row => {
            return {
              ...getExtraSeriesRowData(row),
              name: this.mappingGeoName(row.getCell(groupConfigs[0])),
              value: this.mappingGeoCoordination(
                row.getCell(groupConfigs[0]),
                row.getCell(aggregateConfigs[0]) || defaultColorValue,
                row.getCell(sizeConfigs[0]) || defaultSizeValue,
              ),
            };
          })
          ?.filter(d => !!d.name && d.value !== undefined),
        symbolSize: getScatterSymbolSizeFn(3, max, min, cycleRatio),
        label: {
          formatter: '{b}',
          position: 'right',
          show: showLabel,
        },
        emphasis: {
          label: {
            show: showLabel,
          },
        },
      },
    ];
  }

  protected getTooltip(
    chartDataSet: IChartDataSet<string>,
    groupConfigs: ChartDataSectionField[],
    aggregateConfigs: ChartDataSectionField[],
    sizeConfigs: ChartDataSectionField[],
    styleConfigs: ChartStyleConfig[],
    infoConfigs: ChartDataSectionField[],
  ): {
    trigger: string;
    backgroundColor: string;
    formatter: (params) => string;
    textStyle?: {};
  } {
    const [tooltipFont] = getStyles(styleConfigs,['label'],['tooltipFont']);
    const [backgroundColor] = getStyles(styleConfigs,['label'],['backgroundColor']);
    return {
      trigger: 'item',
      backgroundColor: backgroundColor,
      textStyle: {
        ...tooltipFont
      },
      formatter: function (seriesParams) {
        if (seriesParams.componentType !== 'series') {
          return seriesParams.name;
        }
        // console.log("seriesParams:" + JSON.stringify(seriesParams))

        if(!seriesParams.data) {
          return null;
        }

        return getSeriesTooltips4Polar2(
          chartDataSet,
          seriesParams,
          groupConfigs,
          aggregateConfigs,
          infoConfigs,
          sizeConfigs,
        );
      },
    };
  }

  protected mappingGeoName(sourceName: string): string {
    const targetName = this.geoMap.features.find(f =>
      f.properties.name.includes(sourceName),
    )?.properties.name;
    return targetName;
  }

  protected mappingGeoCoordination(
    sourceName: string,
    ...values: Array<number | string>
  ): Array<number[] | number | string> {
    const properties = this.geoMap.features.find(f =>
      f.properties.name.includes(sourceName),
    )?.properties;

    return (properties?.cp || properties?.center)?.concat(values) || [];
  }

  protected getVisualMap(
    chartDataSet: IChartDataSet<string>,
    groupConfigs: ChartDataSectionField[],
    aggregateConfigs: ChartDataSectionField[],
    sizeConfigs: ChartDataSectionField[],
    styleConfigs: ChartStyleConfig[],
  ): GeoVisualMapStyle[] {
    const [show, type, orient, align, itemWidth, itemHeight, font, left, showLabel, itemSymbol, showMinText, showMaxText] = getStyles(
      styleConfigs,
      ['visualMap'],
      ['show', 'type', 'orient', 'align', 'itemWidth', 'itemHeight', 'font', 'vmLeft', 'showLabel', 'itemSymbol', 'showMinText', 'showMaxText'],
    );
    if (!aggregateConfigs?.length) {
      return [];
    }

    const { min, max } = getDataColumnMaxAndMin2(
      chartDataSet,
      aggregateConfigs?.[0],
    );
    const format = aggregateConfigs?.[0]?.format;
    const inRange = {
      color: [
        aggregateConfigs?.[0]?.color?.start || '#1B9AEE',
        aggregateConfigs?.[0]?.color?.end || '#FA8C15',
      ],
    };
    const piecesBoss = [
      { gte: 0, lt: 0.90, label: '<90%', color: 'rgba(208,73,68,0.76)' }, // (900, 1000]
      { gte: 0.90, lt: 0.98, label: '≥90%', color: '#FF8805' }, // (500, 900]
      { gte: 0.98, label: '≥98%', color: '#4D569C' }, // (310, 500]
    ];
    const piecesFive = [
      { gte: 0, lte: 9, label: '0-9', color: '#ced88b' }, // (900, 1000]
      { gte: 10, lte: 99, label: '10-99', color: '#fab26b' }, // (500, 900]
      { gte: 100, lte: 499, label: '100-499', color: '#ff9baa' }, // (310, 500]
      { gte: 500, lte: 999, label: '500-999', color: '#ff646b' }, // (200, 300]
      { gte: 1000, label: '≥1000', color: '#ff353d' }, // (10, 200]]
    ];
    return [
      {
        type: type !== 'continuous' ? 'piecewise' : 'continuous',
        seriesIndex: 0,
        dimension: this.isNormalGeoMap ? undefined : 2,
        show,
        orient,
        align,
        itemWidth,
        itemHeight,
        inRange,
        showLabel: showLabel,
        itemSymbol: itemSymbol,
        pieces:
          type === 'piecewise'
            ? piecesFive
            : type === 'piecewiseBoss'
            ? piecesBoss
            : [],
        // NOTE 映射最大值和最小值如果一致，会导致map所有区域全部映射成中间颜色，这里做兼容处理
        text: [
          showMinText ? toFormattedValue(max, format) : '',
          showMaxText ? toFormattedValue(min !== max ? min : min - 1, format) : '',
        ],
        min: min !== max ? min : min - 1,
        max,
        textStyle: {
          ...font,
        },
        formatter: value => toFormattedValue(value, format),
        left: left,
      },
    ];
  }
}

export default BasicOutlineMapChart;
