/**
 * Datart
 *
 * Copyright 2021
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Image } from 'antd';
import { DataViewFieldType } from 'app/constants';
import ReactChart from 'app/models/ReactChart';
import {MeasureRelationModel, PageInfo} from 'app/pages/MainPage/pages/ViewPage/slice/types';
import {
  ChartConfig,
  ChartContext,
  ChartDataConfig,
  ChartDataSectionField,
  ChartOptions,
  ChartStyleConfig,
  ChartStyleSectionGroup, IFieldFormatConfig,
} from 'app/types/ChartConfig';
import ChartDataSetDTO, { IChartDataSet } from 'app/types/ChartDataSet';
import {
  getChartStyleSectionGroupValue,
  getColumnRenderName,
  getExtraSeriesRowData,
  getStyles,
  getUnusedHeaderRows,
  getValue,
  toFormattedValue,
  transformToDataSet,
} from 'app/utils/chartHelper';
import { DATARTSEPERATOR } from 'globalConstants';
import { Debugger } from 'utils/debugger';
import { CloneValueDeep, isEmptyArray, Omit } from 'utils/object';
import { ConditionalStyleFormValues } from '../../FormGenerator/Customize/ConditionalStyle';
import AntdTableWrapper from './AntdTableWrapper';
import {
  getCustomBodyCellStyle,
  getCustomBodyRowStyle,
} from './conditionalStyle';
import Config from './config';
import {
  ResizableTitle,
  TableColumnTitle,
  TableComponentsTd,
} from './TableComponents';
import {
  PageOptions,
  TableCellEvents,
  TableColumnsList,
  TableComponentConfig,
  TableHeaderConfig,
  TableStyle,
  TableStyleOptions,
} from './types';
import styled from 'styled-components';
import React from "react";
import {getConvertVariable} from "../../../utils/variableConfig";
import { ChartWidgetContent, UserWidgetConfig, WidgetConf } from "../../../pages/DashBoardPage/pages/Board/slice/types";
import { getTextWidth } from 'utils/utils';

const StyledTextCenter = styled.div`
  text-align:center;
`

class BasicTableChart extends ReactChart {
  useIFrame = false;
  isISOContainer = 'react-table';
  config = Config;
  utilCanvas;
  dataColumnWidths = {};
  tablePadding = 8;
  tableCellBorder = 1;
  cachedAntTableOptions: any = {};
  cachedDatartConfig: ChartConfig = {};
  cacheContext: any = null;
  showSummaryRow = false;
  rowNumberUniqKey = `@datart@rowNumberKey`;
  totalWidth = 0;
  exceedMaxContent = false;
  pageInfo: Partial<PageInfo> | undefined = {
    pageNo: 0,
    pageSize: 0,
    total: 0,
  };
  backendChartId = '';
  storage = undefined as any;
  updateExpire = false;
  constructor(props?) {
    super(AntdTableWrapper, {
      id: props?.id || 'react-table',
      name: props?.name || 'Table',
      icon: props?.icon || 'table',
    });

    this.meta.requirements = props?.requirements || [
      {
        group: [0, 999],
        aggregate: [0, 999],
      },
    ];
  }

  onUpdated(options: ChartOptions, context: ChartContext): void {
    if (!this.isMatchRequirement(options.config)) {
      this.adapter?.unmount();
      return;
    }
    Debugger.instance.measure(
      'Table OnUpdate cost ---> ',
      () => {
        this.backendChartId = options.dataset.backendChartId;

        const temp = localStorage.getItem(this.backendChartId as string);
        if (temp) {
          // 获取当前图表缓存的数据
          this.storage = JSON.parse(temp);
          const updateTime = options.dataset.updateTime
            ? new Date(options.dataset.updateTime).getTime()
            : 0;
          const storageTime = this.storage.updateTime;
          // 若缓存后数据再次更新则缓存失效
          this.updateExpire = storageTime > updateTime ? true : false;
        }
        const tableOptions = this.getOptions(
          context,
          options.dataset,
          options.config,
          options.widgetSpecialConfig,
          options.dataset.summary || {},
          options.dataset.viewMeasureRelation || [],
            options.dataset.measureEnable,
          options.dataset.userConfig,
          options.dataset.tableHideColumn,
        );
        // this.cachedAntTableOptions = Omit(tableOptions, ['dataSource']);
        this.cachedAntTableOptions = Omit(tableOptions, []);
        this.cachedDatartConfig = options.config;
        this.cacheContext = context;
        this.adapter?.updated(tableOptions, context);
      },
      false,
    );
  }

  public onUnMount(options, context?): void {
    this.cachedAntTableOptions = {};
    this.cachedDatartConfig = {};
    this.cacheContext = null;
  }

  public onResize(options, context?): void {
    let columns = this.getDataColumnWidths(
      options.config,
      options.dataset,
      context,
      options.dataset.viewMeasureRelation,
      options.dataset.summary,
      options.dataset.measureEnable,
    );

    const styleConfigs = options.config.styles || [];
    const mixedSectionConfigRows = options.config.datas
      .filter(c => c.key === 'mixed')
      .flatMap(config => config.rows || []);

    // 列顺序、列隐藏设置
    columns = this.columnHideAndOrderSet(columns, styleConfigs, mixedSectionConfigRows, options.dataset.tableHideColumn, options.dataset.userConfig);

    const tableOptions = Object.assign(
      this.cachedAntTableOptions,
      {
        ...this.getAntdTableStyleOptions(
          this.cachedDatartConfig?.styles,
          this.cachedDatartConfig?.settings!,
          context,
        ),
      },
      { columns },
    );
    this.adapter?.updated(tableOptions, context);
  }

  protected getOptions(
    context: ChartContext,
    dataset?: ChartDataSetDTO,
    config?: ChartConfig,
    widgetSpecialConfig?: any,
    summary?: {},
    viewMeasureRelation?: MeasureRelationModel[],
    measureEnable?: string,
    userConfig?: UserWidgetConfig,
    tableHideColumn?: string[],
  ) {
    if (!dataset || !config) {
      return { locale: { emptyText: '  ' } };
    }

    const dataConfigs = config.datas || [];
    const styleConfigs = config.styles || [];
    const settingConfigs = config.settings || [];
    const chartDataSet = transformToDataSet(
      dataset.rows,
      dataset.columns,
      dataConfigs,
    );
    const mixedSectionConfigRows = dataConfigs
      .filter(c => c.key === 'mixed')
      .flatMap(config => config.rows || []);
    const aggregateConfigs = mixedSectionConfigRows.filter(
      r => r.type === DataViewFieldType.NUMERIC,
    );
    this.dataColumnWidths = this.calculateFieldsMaxWidth(
      mixedSectionConfigRows,
      chartDataSet,
      styleConfigs,
      context,
      settingConfigs,
      summary
    );

    this.totalWidth = Object.values<any>(this.dataColumnWidths).reduce(
      (a, b) => a + (b.columnWidthValue || 0),
      0,
    );
    this.exceedMaxContent = this.totalWidth >= context.width;
    const tablePagination = this.getPagingOptions(
      settingConfigs,
      dataset?.pageInfo,
    );
    let tableColumns = this.getColumns(
      mixedSectionConfigRows,
      styleConfigs,
      settingConfigs,
      chartDataSet,
      context,
      viewMeasureRelation,
      measureEnable,
    );

    // 列顺序、列隐藏设置
    tableColumns = this.columnHideAndOrderSet(tableColumns, styleConfigs, mixedSectionConfigRows, tableHideColumn, userConfig);

    const summaryFormatted = this.getSummaryFormatted(summary || {}, aggregateConfigs, mixedSectionConfigRows);
    return {
      rowKey: 'id',
      pagination: tablePagination,
      dataSource: !tableColumns.length ? {} : chartDataSet,
      columns: tableColumns,
      summaryFn: this.getTableSummaryFn(
        settingConfigs,
        chartDataSet,
        tableColumns,
        aggregateConfigs,
        context,
        summaryFormatted,
      ),
      summary: summary,
      viewMeasureRelation: viewMeasureRelation,
      onRow: (_, index) => {
        const row = chartDataSet?.[index];
        const rowData = row?.convertToCaseSensitiveObject();
        return { index, rowData };
      },
      components: this.getTableComponents(
        dataConfigs,
        styleConfigs,
        widgetSpecialConfig,
        mixedSectionConfigRows,
      ),
      ...this.getAntdTableStyleOptions(styleConfigs, settingConfigs, context),
      onChange: (pagination, filters, sorter, extra) => {
        if (extra?.action === 'sort' || extra?.action === 'paginate') {
          this.invokePagingRelatedEvents(
            sorter?.column?.colName,
            sorter?.order,
            pagination?.current,
            sorter?.column?.aggregate,
          );
        }
      },
      rowClassName: (_, index) => {
        return index % 2 === 0
          ? 'datart-basic-table-odd'
          : 'datart-basic-table-even';
      },
      tableStyleConfig: this.getTableStyle(styleConfigs, settingConfigs),
    };
  }


  private columnHide(
    tableColumns?: TableColumnsList[],
    tableHideColumn?: string[],
  ) {
    return tableColumns?.flatMap(item => {
      if(item.colName && !tableHideColumn?.includes(item.colName)) {
        if(!item.children?.length) return item
        const children = this.columnHide(item.children, tableHideColumn)
        return children?.length ? {...item, children} : []
      } else
        return []
    })
  }

  private columnHideAndOrderSet(
    tableColumns: TableColumnsList[],
    styleConfigs?: ChartStyleConfig[],
    mixedSectionConfigRows?: ChartDataSectionField[],
    tableHideColumn?: string[],
    userConfig?: UserWidgetConfig,
  ) {
    // 通过控制器配置隐藏列
    if (tableHideColumn?.length) {
      tableColumns = this.columnHide(tableColumns, tableHideColumn)
    }

    // 用户自定义隐藏列
    if (userConfig?.tableHiddenCol) {
      const userHideCol = userConfig.tableHiddenCol.map(item => item.colName);
      tableColumns = tableColumns.filter(item => item.colName && !userHideCol.includes(item.colName))
    }

    // 图表配置隐藏列
    const colsConfig = this.getColsConfig(styleConfigs || [], mixedSectionConfigRows || []).filter(i => i.hideColDisplay === true);
    if(colsConfig.length) {
      tableColumns = tableColumns.filter(i => !colsConfig.some(x => x.c.colName === i.colName))
    }

    if (userConfig?.tableColOrder) {
      tableColumns.sort((a, b) => {
        if (a.key === `${DATARTSEPERATOR}id`) {
          return -1
        }
        if (b.key === `${DATARTSEPERATOR}id`) {
          return 1
        }
        let indexA;
        let indexB;
        if (typeof a.title == "string") {
          indexA = userConfig?.tableColOrder?.findIndex(item => a.title === item.alias);
        } else if (typeof a == "object") {
          indexA = userConfig?.tableColOrder?.findIndex(item => (a.title as any)?.props.title === item.alias);
        }
        if (typeof b.title == "string") {
          indexB = userConfig?.tableColOrder?.findIndex(item => b.title === item.alias);
        } else if (typeof b == "object") {
          indexB = userConfig?.tableColOrder?.findIndex(item => (b.title as any)?.props.title === item.alias);
        }
        if (indexA < 0) indexA = 999;
        if (indexB < 0) indexB = 999;
        return indexA > indexB ? 1 : -1
      })
    }
    return tableColumns;
  }

  // 以下方法已复制到别处，要修改时，记得把别处的一起修改
  private getColsConfig(
    styleConfigs: ChartStyleConfig[],
    mixedSectionConfigRows: ChartDataSectionField[],
  ) {
    const getAllColumnListInfo: ChartStyleSectionGroup[] = getValue(
      styleConfigs,
      ['column', 'modal', 'list'],
      'rows',
    );
    return mixedSectionConfigRows.map(c => {
      const [hideColDisplay, hideColDownload] = getStyles(
        getAllColumnListInfo,
        [c.uid!, 'columnStyle'],
        ['hideColDisplay', 'hideColDownload'],
      );

      return {
        c,
        hideColDisplay,
        hideColDownload,
      }
    })    
  }

  private getDataColumnWidths(
    config: ChartConfig,
    dataset: ChartDataSetDTO,
    context,
    viewMeasureRelation?: MeasureRelationModel[],
    summary?: {},
    measureEnable?: string,
  ): TableColumnsList[] {
    const dataConfigs = config.datas || [];
    const styleConfigs = config.styles || [];
    const settingConfigs = config.settings || [];
    const chartDataSet = transformToDataSet(
      dataset.rows,
      dataset.columns,
      dataConfigs,
    );

    const mixedSectionConfigRows = dataConfigs
      .filter(c => c.key === 'mixed')
      .flatMap(config => config.rows || []);

    this.dataColumnWidths = this.calculateFieldsMaxWidth(
      mixedSectionConfigRows,
      chartDataSet,
      styleConfigs,
      context,
      settingConfigs,
      summary
    );
    this.totalWidth = Object.values<any>(this.dataColumnWidths).reduce(
      (a, b) => a + (b.columnWidthValue || 0),
      0,
    );
    this.exceedMaxContent = this.totalWidth >= context.width;
    return this.getColumns(
      mixedSectionConfigRows,
      styleConfigs,
      settingConfigs,
      chartDataSet,
      context,
      viewMeasureRelation,
      measureEnable,
    );
  }

  private getTableStyle(
    styles: ChartStyleConfig[],
    settingConfigs: ChartStyleConfig[],
  ): TableStyle {
    const [oddBgColor, oddFontColor, evenBgColor, evenFontColor, tableBorderColor] = getStyles(
      styles,
      ['tableBodyStyle'],
      ['oddBgColor', 'oddFontColor', 'evenBgColor', 'evenFontColor', 'tableBorderColor'],
    );
    const [rightFixedColumns] = getStyles(
      styles,
      ['style'],
      ['rightFixedColumns'],
    );
    const [backgroundColor, summaryFont, summaryAlign] = getStyles(
      settingConfigs,
      ['summary'],
      ['summaryBcColor', 'summaryFont', 'summaryAlign'],
    );
    return {
      odd: {
        backgroundColor: oddBgColor,
        color: oddFontColor,
      },
      even: {
        backgroundColor: evenBgColor,
        color: evenFontColor,
      },
      isFixedColumns: rightFixedColumns ? true : false,
      summaryStyle: Object.assign({ backgroundColor }, {summaryFont, ...{textAlign: summaryAlign}}),
      tableBorderColor,
    };
  }

  private getSummaryFormatted(
    summary: {},
    aggregateConfigs: ChartDataSectionField[],
    mixedSectionConfigRows: ChartDataSectionField[]
  ): { colName: string, format: IFieldFormatConfig | undefined, value: any }[] | undefined {
    const isAggreate = aggregateConfigs && aggregateConfigs[0] && aggregateConfigs[0].aggregate;
    return mixedSectionConfigRows.filter(c =>
      summary[(isAggreate ? c.aggregate + '(' + c.colName + ')' : c.colName)] && summary[(isAggreate ? c.aggregate + '(' + c.colName + ')' : c.colName)] != undefined
    ).map(c => {
      const relColname = isAggreate ? c.aggregate + '(' + c.colName + ')' : c.colName
      return {colName: c.colName, format: c.format, value: summary[relColname]};
    })
  }

  private getTableSummaryFn(
    settingConfigs: ChartStyleConfig[],
    chartDataSet: IChartDataSet<string>,
    tableColumns: TableColumnsList[],
    aggregateConfigs: ChartDataSectionField[],
    context: ChartContext,
    summary: { colName: string, format: IFieldFormatConfig | undefined, value: any }[] | undefined,
  ): ((value) => { summarys: Array<string | null> }) | undefined {
    const [aggregateFields] = getStyles(
      settingConfigs,
      ['summary'],
      ['aggregateFields'],
    );
    const isAggreate = aggregateConfigs && aggregateConfigs[0] && aggregateConfigs[0].aggregate;
    this.showSummaryRow = aggregateFields && aggregateFields.length > 0;
    if (!this.showSummaryRow) {
      return;
    }

    const aggregateFieldConfigs = aggregateConfigs.filter(c =>
      aggregateFields.includes(c.uid),
    );
    if (!aggregateFieldConfigs.length) {
      return;
    }

    const _flatChildren = node => {
      if (Array.isArray(node?.children)) {
        return (node.children || []).reduce((acc, cur) => {
          return acc.concat(..._flatChildren(cur));
        }, []);
      }
      return [node];
    };
    const flatHeaderColumns: TableColumnsList[] = (tableColumns || []).reduce(
      (acc, cur) => {
        return acc.concat(..._flatChildren(cur));
      },
      [],
    );

    return (_): { summarys: Array<string | null> } => {
      return {
        summarys: flatHeaderColumns
          .map(c => c.key)
          .map((k, index) => {
            const currentSummaryField = aggregateFieldConfigs.find(
              c => chartDataSet.getFieldKey(c) === k,
            );
            const currentSummary = summary?.find(
              c => c.colName === currentSummaryField?.colName
            )
            if (currentSummaryField) {
              const total = currentSummary ? currentSummary.value : 0;
              // const scale =
              //   settingConfigs
              //     .find(item => item.key === 'summary')
              //     ?.rows?.find(item => item.key === 'scale')?.value || 2;

              return (
                (!index
                  ? context?.translator?.('viz.palette.graph.summary') + ': '
                  : '') + toFormattedValue(total, currentSummary?.format)
              );
              // const total = chartDataSet?.map((dc: any) =>
              //   dc.getCell(currentSummaryField),
              // );
              // return (
              //   (!index
              //     ? context?.translator?.('viz.palette.graph.summary') + ': '
              //     : '') +
              //   toFormattedValue(
              //     total.reduce((acc, cur) => acc + cur, 0),
              //     currentSummaryField.format,
              //   )
              // );
            }
            if (k === `${DATARTSEPERATOR}id` || !index) {
              return context?.translator?.('viz.palette.graph.summary');
            }
            return null;
          }),
      };
    };
  }



  private calculateFieldsMaxWidth(
    mixedSectionConfigRows: ChartDataSectionField[],
    chartDataSet: IChartDataSet<string>,
    styleConfigs: ChartStyleConfig[],
    context: ChartContext,
    settingConfigs: ChartStyleConfig[],
    summary?: {}
  ): {
    [x: string]: {
      columnWidthValue?: number | undefined;
      getUseColumnWidth?: boolean | undefined;
    };
  } {
    const [fontFamily, fontSize, fontWeight] = getStyles(
      styleConfigs,
      ['tableBodyStyle'],
      ['fontFamily', 'fontSize', 'fontWeight'],
    );
    const [headerFont] = getStyles(
      styleConfigs,
      ['tableHeaderStyle'],
      ['font'],
    );
    const [tableHeaders] = getStyles(
      styleConfigs,
      ['header', 'modal'],
      ['tableHeaders'],
    );
    const [enableRowNumber] = getStyles(
      styleConfigs,
      ['style'],
      ['enableRowNumber'],
    );
    const getAllColumnListInfo: ChartStyleSectionGroup[] = getValue(
      styleConfigs,
      ['column', 'modal', 'list'],
      'rows',
    );
    const [summaryFont] = getStyles(
      settingConfigs,
      ['summary'],
      ['summaryFont'],
    );
    const getRowNumberWidth = maxContent => {
      if (!enableRowNumber) {
        return 0;
      }

      return this.getTextWidth(
        context,
        maxContent,
        fontWeight,
        fontSize,
        fontFamily,
      );
    };

    const rowNumberUniqKeyWidth =
      getRowNumberWidth(chartDataSet?.length) +
      this.tablePadding * 2 +
      this.tableCellBorder * 2;

    const rowNumberUniqKeyHeaderWidth = this.getTextWidth(
      context,
      context?.translator?.('viz.palette.graph.number'),
      headerFont?.fontWeight,
      headerFont?.fontSize,
      headerFont?.fontFamily,
    );

    const rowSummaryWidth = this.getTextWidth(
      context,
      context?.translator?.('viz.palette.graph.summary'),
      summaryFont?.fontWeight,
      summaryFont?.fontSize,
      summaryFont?.fontFamily,
    );
    const aggregateConfigs = mixedSectionConfigRows.filter(
      r => r.type === DataViewFieldType.NUMERIC,
    );
    const maxContentByFields: {
      [p: string]: {
        columnWidthValue?: number | undefined;
        getUseColumnWidth?: undefined | boolean;
      };
    }[] = mixedSectionConfigRows.map(c => {
      const header = this.findHeader(c.uid, tableHeaders);
      const rowUniqKey = chartDataSet.getFieldKey(c);

      const [columnWidth, getUseColumnWidth] = getStyles(
        getAllColumnListInfo,
        [c.uid!, 'columnStyle'],
        ['columnWidth', 'useColumnWidth'],
      );
      const datas = chartDataSet?.map(dc => {
        const text = dc.getCell(c);
        const width = this.getTextWidth(
          context,
          toFormattedValue(text, c.format),
          fontWeight,
          fontSize,
          fontFamily,
        );

        const headerWidth = this.getTextWidth(
          context,
          c.alias?.name || header?.label || chartDataSet.getFieldKey(c),
          headerFont?.fontWeight,
          headerFont?.fontSize,
          headerFont?.fontFamily,
        );
        const currentSummaryField = aggregateConfigs.find(
          ac => ac.uid === c.uid,
        );
        // const total = chartDataSet?.map((dc: any) =>
        //   dc.getCell(currentSummaryField),
        // );
        // 这里没搞清楚为什么要把所有字段拼接，先按最大的来：
        // const summaryText = total.reduce((acc, cur) => acc + cur, 0);
        // const summaryText = total.reduce((acc, cur) => acc?.length > cur?.length ? acc : cur, 0);
        const isAggreate = aggregateConfigs && aggregateConfigs[0] && aggregateConfigs[0].aggregate;
        const summaryText = (summary && currentSummaryField) ?  summary[(isAggreate ? currentSummaryField.aggregate + '(' + currentSummaryField.colName + ')' : currentSummaryField.colName)] : 0;

        const summaryWidth = this.getTextWidth(
          context,
          toFormattedValue(summaryText, c.format),
          summaryFont?.fontWeight,
          summaryFont?.fontSize,
          summaryFont?.fontFamily,
        );
        const sorterIconWidth = 12;
        return Math.max(
          width,
          headerWidth +
          sorterIconWidth +
          (c?.alias?.desc ? headerFont?.fontSize || 12 : 0),
          summaryWidth + sorterIconWidth,
        );
      });

      return {
        [rowUniqKey]: {
          columnWidthValue: getUseColumnWidth
            ? columnWidth || 100
            : (datas.length ? Math.max(...datas) : 0) +
            this.tablePadding * 2 +
            this.tableCellBorder * 2,
          getUseColumnWidth,
        },
      };
    });
    maxContentByFields.push({
      [this.rowNumberUniqKey]: {
        columnWidthValue: enableRowNumber
          ? Math.max(
            rowNumberUniqKeyWidth,
            rowNumberUniqKeyHeaderWidth +
            this.tablePadding * 2 +
            this.tableCellBorder * 2,
            rowSummaryWidth +
            this.tablePadding * 2 +
            this.tableCellBorder * 2,
          )
          : 0,
      },
    });
    return maxContentByFields.reduce((acc, cur: any) => {
      return Object.assign({}, acc, { ...cur });
    }, {});
  }

  protected getTableComponents(
    dataConfigs: ChartDataConfig[],
    styleConfigs: ChartStyleConfig[],
    widgetSpecialConfig: { env: string | undefined;[x: string]: any },
    mixedSectionConfigRows: ChartDataSectionField[],
  ): TableComponentConfig {
    const linkFields = widgetSpecialConfig?.linkFields;
    const jumpField = widgetSpecialConfig?.jumpField;
    const drillThroughFields = widgetSpecialConfig?.drillThroughFields;


    const [tableHeaders] = getStyles(
      styleConfigs,
      ['header', 'modal'],
      ['tableHeaders'],
    );

    let dataHeaderStyle = {};
    if (dataConfigs[0].allTableHeaders) {
      dataConfigs[0].allTableHeaders.forEach(element => {
        if (element.uid && element.styleSetting) {
          const [headerBgColor, headerFont, headerTextAlign, customStyle] = getStyles(
            [element.styleSetting],
            [element.styleSetting.key],
            ['bgColor', 'font', 'align', 'customStyle'],
          );

          dataHeaderStyle[element.uid] = {
            headerBgColor: headerBgColor,
            headerFont: headerFont,
            headerTextAlign: headerTextAlign,
            customStyle: (customStyle || '').split(';').filter(i => i).reduce((t, c) => {
                const [key, value] = c.split(':');
                if(key && value) t[key.trim()] = value.trim();
                return t;
            }, {})
          };
        }
      });
    }

    // 获取表头样式
    const [headerBgColor, headerFont, headerTextAlign] = getStyles(
      styleConfigs,
      ['tableHeaderStyle'],
      ['bgColor', 'font', 'align'],
    );
    
    
    // 获取表体样式
    const [fontFamily, fontSize, fontWeight, fontStyle, bodyTextAlign] =
      getStyles(
        styleConfigs,
        ['tableBodyStyle'],
        ['fontFamily', 'fontSize', 'fontWeight', 'fontStyle', 'align'],
      );

    const getAllColumnListInfo: ChartStyleSectionGroup[] = getValue(
      styleConfigs,
      ['column', 'modal', 'list'],
      'rows',
    );
    let allConditionalStyle: ConditionalStyleFormValues[] = [];
    getAllColumnListInfo?.forEach(info => {
      const [getConditionalStyleValue]: Array<
        ConditionalStyleFormValues[] | undefined
      > = getStyles(
        info.rows!,
        ['conditionalStyle'],
        ['conditionalStylePanel'],
      );
      if (Array.isArray(getConditionalStyleValue)) {
        allConditionalStyle = [
          ...allConditionalStyle,
          ...getConditionalStyleValue,
        ];
      }
    });

    return {
      header: {
        cell: props => {
          // 设置表头样式
          const uid = props.uid;
          const label = props.label;
          const key = uid;
          const { style, title, align, ...rest } = props;
          const header = this.findHeader(uid, tableHeaders || []);
          const font =
            dataHeaderStyle[key] && dataHeaderStyle[key].headerFont
              ? dataHeaderStyle[key].headerFont
              : headerFont;
          
          const customStyle = dataHeaderStyle[key] && dataHeaderStyle[key].customStyle;
          const cellCssStyle = {
            textAlign:
              dataHeaderStyle[key] && dataHeaderStyle[key].headerTextAlign
                ? dataHeaderStyle[key].headerTextAlign
                : (align || headerTextAlign),
            backgroundColor:
              dataHeaderStyle[key] && dataHeaderStyle[key].headerBgColor
                ? dataHeaderStyle[key].headerBgColor
                : headerBgColor,
            ...font,
            fontSize: +font?.fontSize,
            ...customStyle,
          };

          if (header && header.style) {
            const fontStyle = header.style?.font?.value;
            
            Object.assign(
              cellCssStyle,
              {
                textAlign: header.style.align,
                backgroundColor: header.style.backgroundColor,
              },
              { ...fontStyle },
            );
          }

          return (
            // <ResizableTitle
            //   {...rest}
            //   style={Object.assign(cellCssStyle, style)}
            // />
          // 表格禁止拖拽
          <th {...rest}
              style={Object.assign(cellCssStyle, style)} />
          );
        },
      },
      body: {
        cell: props => {
          const { style, key, rowData, record, sensitiveFieldName, ...rest } = props;
          // debugger
          const uid = props.uid;
          const [conditionalStyle] = getStyles(
            getAllColumnListInfo,
            [uid, 'conditionalStyle'],
            ['conditionalStylePanel'],
          );
          const [align] = getStyles(
            getAllColumnListInfo,
            [uid, 'columnStyle'],
            ['align'],
          );


          const valueTargets = (conditionalStyle || []).map((i) => record && record.columnIndexTable && record[record.columnIndexTable[((i.valueTarget || "").toUpperCase())]]) || [];


          const conditionalCellStyle = getCustomBodyCellStyle(
            props?.cellValue,
            conditionalStyle,
            valueTargets,
          );
          
          // const sensitiveFieldName = Object.keys(rowData || {})?.[0];

          const useColumnWidth =
            this.dataColumnWidths?.[props.dataIndex]?.getUseColumnWidth;
          // 判断是否是序号,序号居中
          const rowIndex = props.dataIndex? false: true;
          const _getBodyTextAlignStyle = alignValue => {
            if (alignValue && alignValue !== 'default') {
              return alignValue;
            }
            if (bodyTextAlign === 'default') {
              const type = mixedSectionConfigRows.find(
                v => v.uid === uid,
              )?.type;
              if (type === 'NUMERIC') {
                return 'right';
              }
              return 'left';
            }
            return bodyTextAlign;
          };

          return (
            <TableComponentsTd
              {...rest}
              style={Object.assign(style || {}, conditionalCellStyle, {
                textAlign: rowIndex? 'center': _getBodyTextAlignStyle(align),
              })}
              isLinkCell={linkFields?.includes(sensitiveFieldName)}
              isJumpCell={jumpField === sensitiveFieldName || drillThroughFields?.includes(sensitiveFieldName)}
              useColumnWidth={useColumnWidth}
            />
          );
        },
        row: props => {
          const { style, rowData, ...rest } = props;
          // NOTE: rowData is case sensitive row keys object
          const rowStyle = getCustomBodyRowStyle(rowData, allConditionalStyle);
          return <tr {...rest} style={Object.assign(style || {}, rowStyle)} />;
        },
        wrapper: props => {
          const { style, ...rest } = props;
          const bodyStyle = {
            textAlign: bodyTextAlign === 'default' ? 'left' : bodyTextAlign,
            fontFamily,
            fontWeight,
            fontStyle,
            fontSize: +fontSize,
          };
          return (
            <tbody {...rest} style={Object.assign(style || {}, bodyStyle)} />
          );
        },
      },
    };
  }

  private updateTableColumns(e, { size }, index) {
    const { columns } = this.cachedAntTableOptions;
    if (columns.some(col => col.isGroup)) {
      return false;
    }
    const nextColumns = [...columns];
    nextColumns[index] = {
      ...nextColumns[index],
      width: size.width,
    };
    const tableOptions = Object.assign(this.cachedAntTableOptions, {
      columns: nextColumns,
    });
    this.adapter?.updated(tableOptions, this.cacheContext);
  }

  protected getColumns(
    mixedSectionConfigRows: ChartDataSectionField[],
    styleConfigs: ChartStyleConfig[],
    settingConfigs: ChartStyleConfig[],
    chartDataSet: IChartDataSet<string>,
    context: ChartContext,
    viewMeasureRelation?: MeasureRelationModel[],
    measureEnable?: string,
  ): TableColumnsList[] {
    const [enableRowNumber, leftFixedColumns, rightFixedColumns] = getStyles(
      styleConfigs,
      ['style'],
      ['enableRowNumber', 'leftFixedColumns', 'rightFixedColumns'],
    );
    const [tableHeaderStyles] = getStyles(
      styleConfigs,
      ['header', 'modal'],
      ['tableHeaders'],
    );
    const [enableSort] = getStyles(
      styleConfigs,
      ['tableHeaderStyle'],
      ['enableSort'],
    )
    const [pageSize] = getStyles(settingConfigs, ['paging'], ['pageSize']);

    const tempHeader =
      this.storage && this.updateExpire
        ? this.storage.datas.value
        : tableHeaderStyles;
    
    const tempGroupConfigs =
      this.storage && this.updateExpire
        ? this.storage.config[0].rows
        : mixedSectionConfigRows;

    

    const tempFlatConfigs =
      this.storage && this.updateExpire
        ? this.storage.datas.value
        : mixedSectionConfigRows;

    const columnsList =
      !tableHeaderStyles || tableHeaderStyles.length === 0
        ? this.getFlatColumns(tempFlatConfigs, chartDataSet, styleConfigs, viewMeasureRelation, measureEnable)
        : this.getGroupColumns(
          tempGroupConfigs,
          tempHeader,
          chartDataSet,
          styleConfigs,
          viewMeasureRelation,
          measureEnable,
        );
    const rowNumbers: TableColumnsList[] = enableRowNumber
      ? [
        {
          key: `${DATARTSEPERATOR}id`,
          title: context?.translator?.('viz.palette.graph.number'),
          width:
            this.dataColumnWidths?.[this.rowNumberUniqKey]
              ?.columnWidthValue || 0,
          fixed: leftFixedColumns || rightFixedColumns ? 'left' : null,
          onHeaderCell: () => {
            return {align: 'center'}
          },
          render: (value, row, rowIndex) => {
            const pageNo = this.pageInfo?.pageNo || 1;
            return <StyledTextCenter>{(pageNo - 1) * (pageSize || 100) + rowIndex + 1}</StyledTextCenter>;
          },
        },
      ]
      : [];
    const res = rowNumbers.concat(columnsList);
    res.forEach(item => {
      this.convertHeadColumnTitles(item);
      // if (!enableSort) {
      //   item.sorter = false;
      // }
    });
    return res;
  }

  convertHeadColumnTitles(
    columnList: TableColumnsList,
  ): void {
    if (columnList.children && columnList.children.length > 0) {
      columnList.children.forEach(c => this.convertHeadColumnTitles(c))
    }
    if (typeof columnList.title === 'string') {
      columnList.title = columnList.title.replace(/\$\{.*\}/, function(a) { return getConvertVariable(a) });
      columnList.title = columnList.title.replace(/\$\{.*\}/, function(a) {
        return new Function('params', a.substring(2, a.length - 1))() });
    } else {
      if (columnList.title?.props.title) {
        columnList.title.props.title = columnList.title?.props.title.replace(/\$\{.*\}/, function(a) { return getConvertVariable(a) });
        columnList.title.props.title = columnList.title?.props.title.replace(/\$\{.*\}/, function(a) {
          return new Function('params', a.substring(2, a.length - 1))() });
      }
    }
  }

  convertExpressionTitles(
    text: string,
  ): string {
    if (typeof text === 'string') {
      let replacedText = text.replace(/\$\{.*\}/, function(a) { return getConvertVariable(a) });
      replacedText = replacedText.replace(/\$\{.*\}/, function(a) {
        return new Function('params', a.substring(2, a.length - 1))() });
      return replacedText;
    }
    return text;
  }

  getFlatColumns(
    dataConfigs: TableHeaderConfig[],
    chartDataSet: IChartDataSet<string>,
    styleConfigs: ChartStyleConfig[],
    viewMeasureRelation?: MeasureRelationModel[],
    measureEnable?: string,
  ): TableColumnsList[] {
    const [autoMergeFields] = getStyles(
      styleConfigs,
      ['style'],
      ['autoMergeFields'],
    );

    const [autoMergeRows] = getStyles(
      styleConfigs,
      ['style'],
      ['autoMergeRows'],
    );

    const getAllColumnListInfo: ChartStyleSectionGroup[] = getValue(
      styleConfigs,
      ['column', 'modal', 'list'],
      'rows',
    );


    const needMergeRows = dataConfigs.map(c => {
      const [rowMerge, rowMergeTargteCol] = getStyles(
        getAllColumnListInfo,
        [c.uid!, 'columnStyle'],
        ['rowMerge', 'rowMergeTargteCol'],
      );

      return {
        c,
        tc: rowMergeTargteCol ? dataConfigs.find(i => i.uid === rowMergeTargteCol) || c : c,
        rowMerge,
        rowMergeTargteCol,
      }
    }).filter(i => i.rowMerge);


    const currAutoMergeRows = dataConfigs.filter((c, cIndex) =>
      autoMergeRows?.includes(c.uid) && autoMergeRows?.includes(dataConfigs[cIndex - 1]?.uid)
    )

    const currAutoMergeRowsId = currAutoMergeRows?.map(curr => curr.uid);

    const columnColSpans = currAutoMergeRows && currAutoMergeRows.length > 0
      ? chartDataSet
        ?.map((dc) => {
          return Object.values(dc.convertToObject()).reverse().reduce((acc, cur, index, array) => {
            const indexR = dataConfigs.length - index - 1;
            let prevColSpan = 0;
            if (index > 0) {
              prevColSpan = acc[index - 1].nextColSpan;
            }
            if (array[index + 1] === cur && (currAutoMergeRowsId?.includes(dataConfigs[indexR].uid))) {
              return acc.concat([
                { colSpan: 0, nextColSpan: prevColSpan + 1 }
              ]);
            } else {
              // let prevColSpan = 0;
              // if (acc.length === index && index > 0) {
              //   prevColSpan = acc[index - 1].nextColSpan;
              // }
              return acc.concat([
                { colSpan: prevColSpan + 1, nextColSpan: 0 },
              ]);
            }
            return acc.concat([{}])
          }, [] as any[])
            .map(x => x.colSpan)
            .reverse()
        })
      : [];

    const columnList: TableColumnsList[] = dataConfigs.map((c, cIndex) => {
      const colName = c.colName;

      const [ellipsis] = getStyles(
        getAllColumnListInfo,
        [c.uid!, 'columnStyle'],
        ['ellipsis'],
      );

      const colCoonfig = needMergeRows.find(i => i.c.uid === c.uid);

      const columnMergeRowSpans = !colCoonfig ? [] : chartDataSet.map(dc => dc.getCell(colCoonfig.tc)).reduce((t: { value?: any, index: number, arr: number[] }, c, i, arr) => {
        if(t.value === c) {
          t.arr[t.index] = t.arr[t.index] + 1;
          return {
            ...t,
            arr: [...t.arr, 0],
          }
        } else {
          return {
            value: c,
            index: i,
            arr: [...t.arr, 1]
          }
        }
      }, { index: - 1, arr: [] }).arr;


      const columnRowSpans = (autoMergeFields || []).includes(c.uid) && !colCoonfig
        ? chartDataSet
          ?.map(dc => dc.getCell(c))
          .reverse()
          .reduce((acc, cur, index, array) => {
            if (array[index + 1] === cur) {
              let prevRowSpan = 0;
              if (acc.length === index && index > 0) {
                prevRowSpan = acc[index - 1].nextRowSpan;
              }
              return acc.concat([
                { rowSpan: 0, nextRowSpan: prevRowSpan + 1 },
              ]);
            } else {
              let prevRowSpan = 0;
              if (acc.length === index && index > 0) {
                prevRowSpan = acc[index - 1].nextRowSpan;
              }
              return acc.concat([
                { rowSpan: prevRowSpan + 1, nextRowSpan: 0 },
              ]);
            }
          }, [] as any[])
          .map(x => x.rowSpan)
          .reverse()
        : [];
      const storageWidth = c?.width;
      const columnConfig = this.dataColumnWidths?.[chartDataSet.getFieldKey(c)];
      const colMaxWidth =
          !this.exceedMaxContent &&
          Object.values<{ getUseColumnWidth: undefined | boolean }>(
              this.dataColumnWidths,
          ).some(item => item.getUseColumnWidth)
              ? columnConfig?.getUseColumnWidth
              ? columnConfig?.columnWidthValue
              : (getTextWidth(this.convertExpressionTitles(getColumnRenderName(c))) + 20)
              : Math.max(columnConfig?.columnWidthValue, (getTextWidth(this.convertExpressionTitles(getColumnRenderName(c))) + 20));

      const columnWidth = c.styleSetting?.rows?.find(e => e.key === 'columnWidth')?.value;


          const colWidth =  columnWidth === "null" ? undefined : parseFloat(columnWidth) || Math.max((getTextWidth(this.convertExpressionTitles(getColumnRenderName(c))) + 20), columnConfig?.columnWidthValue, storageWidth ? storageWidth : colMaxWidth);
          // const colWidth = columnConfig?.getUseColumnWidth ? columnConfig?.columnWidthValue : storageWidth ? storageWidth : colMaxWidth;

      let enableSort = true;
      const index = c.styleSetting?.rows?.findIndex(e => e.key === 'enableSort');
      if (index || index === 0) {
        if (c.styleSetting?.rows?.[index]) {
          enableSort = c.styleSetting?.rows?.[index].value
        }
      }

      
      return {
        sorter: enableSort,
        showSorterTooltip: false,
        label: colName,
        title: <TableColumnTitle
        title={getColumnRenderName(c)}
        desc={c?.alias?.desc}
        uid={chartDataSet.getFieldKey(c)}
        columnName={colName}
        viewMeasureRelation={viewMeasureRelation || []}
        measureEnable={measureEnable}
      />,
        dataIndex: chartDataSet.getFieldIndex(c),
        key: chartDataSet.getFieldKey(c),
        aggregate: c?.aggregate,
        colName,
        width: colWidth,
        fixed: null,
        ellipsis: ellipsis === false ? false : {
          showTitle: false,
        },
        onHeaderCell: record => {
          return {
            ...Omit(record, [
              'dataIndex',
              'onHeaderCell',
              'onCell',
              'colName',
              'render',
              'sorter',
              'showSorterTooltip',
            ]),
            uid: c.uid,
            onResize: (e, node) => {
              this.updateTableColumns(e, node, cIndex);
            },
          };
        },
        onCell: (record, rowIndex) => {
          const row = chartDataSet[rowIndex];
          let cellValue = row.getCell(c);
          if(cellValue?.toString()?.includes("$$")) cellValue = cellValue.split("$$")[0]
          // const rowData = { [chartDataSet.getFieldOriginKey(c)]: cellValue };
          // const rowData = row?.convertToCaseSensitiveObject();
          const { rowData } = getExtraSeriesRowData(row);
          rowData[chartDataSet.getFieldOriginKey(c)] = cellValue;

          return {
            uid: c.uid,
            cellValue,
            dataIndex: row.getFieldKey(c),
            sensitiveFieldName: chartDataSet.getFieldOriginKey(c),
            rowData,
            record: record,
            ...this.registerTableCellEvents(
              colName,
              cellValue,
              rowIndex,
              rowData,
              c.aggregate,
            ),
          };
        },
        render: (value, row, rowIndex) => {
          if (value && this.isValidHttpUrl(value)) {
            const split = value.split(";");
            return (<>
              {split.map(item => {
                return (<Image width={50} height={90} src={item} style={{paddingLeft: 5}} />)
              })}
              </>);
          } else {
            const formattedValue = toFormattedValue(value, c.format);

            if(columnMergeRowSpans.length) {
              let res = {
                children: formattedValue,
                props: { rowSpan: 1, cellValue: value, colSpan: 1 }
              }
              res.props.rowSpan = columnMergeRowSpans[rowIndex];
              return res;
            }

            if (!(autoMergeFields || []).includes(c.uid) && !(autoMergeRows || []).includes(c.uid)) {
              return formattedValue;
            }
            let res = {
              children: formattedValue,
              props: { rowSpan: 1, cellValue: value, colSpan: 1 }
            }

            if ((autoMergeFields || []).includes(c.uid)) {
              res.props.rowSpan = columnRowSpans[rowIndex]
            }
            if ((autoMergeRows || []).includes(c.uid) && columnColSpans && columnColSpans.length > 0) {
              res.props.colSpan = columnColSpans[rowIndex][cIndex]
            }
            return res;
          }
          // return {
          //   children: formattedValue,
          //   props: { rowSpan: columnRowSpans[rowIndex], cellValue: value, colSpan: columnColSpans[rowIndex][cIndex] },
          // };
        },
      };
    });
    return this.getFixedColumns(columnList, styleConfigs);
  }

  convertHeadColumnString(
    title: string,
  ): string {
    title = title.replace(/\$\{.*\}/, function(a) { return getConvertVariable(a) });
    title = title.replace(/\$\{.*\}/, function(a) {
      return new Function('params', a.substring(2, a.length - 1))() });
    return title;
  }

  getFixedColumns(
    list: TableColumnsList[],
    styleConfigs: ChartStyleConfig[],
  ): TableColumnsList[] {
    const [leftFixedColumns, rightFixedColumns] = getStyles(
      styleConfigs,
      ['style'],
      ['leftFixedColumns', 'rightFixedColumns'],
    );
    let columnsList = CloneValueDeep(list);
    leftFixedColumns &&
      (columnsList = columnsList.map((item, index) => {
        if (index < Math.min(leftFixedColumns, columnsList.length - 1)) {
          item.fixed = 'left';
        }
        return item;
      }));
    rightFixedColumns &&
      (columnsList = columnsList
        .reverse()
        .map((item, index) => {
          if (index < rightFixedColumns && !item.fixed) {
            item.fixed = 'right';
          }
          return item;
        })
        .reverse());
    return columnsList;
  }

  getGroupColumnsOfFlattenedColumns = (
    tableHeader: TableHeaderConfig[],
    mixedSectionConfigRows: ChartDataSectionField[],
    chartDataSet: IChartDataSet<string>,
  ): TableHeaderConfig[] => {
    const newMixedConfig = mixedSectionConfigRows?.concat();
    let list: TableHeaderConfig[] = [];
    const _getFlattenedChildren = tableHeaderStylesConfig => {
      if (tableHeaderStylesConfig.children?.length) {
        tableHeaderStylesConfig.children.map(item =>
          _getFlattenedChildren(item),
        );
      } else {
        const currentConfigIndex = newMixedConfig?.findIndex(
          c =>
            chartDataSet.getFieldKey(c) ===
            chartDataSet.getFieldKey(tableHeaderStylesConfig),
        );
        if (currentConfigIndex >= 0) {
          list.push(
            Object.assign(
              {},
              tableHeaderStylesConfig,
              newMixedConfig?.[currentConfigIndex],
            ),
          );
          newMixedConfig?.splice(currentConfigIndex, 1);
        }
      }
    };
    tableHeader.forEach(item => {
      if (item.children?.length) {
        item.children.map(items => _getFlattenedChildren(items));
      } else {
        const currentConfigIndex = newMixedConfig?.findIndex(
          c => chartDataSet.getFieldKey(c) === chartDataSet.getFieldKey(item),
        );
        if (currentConfigIndex >= 0) {
          list.push(
            Object.assign({}, item, newMixedConfig?.[currentConfigIndex]),
          );
          newMixedConfig?.splice(currentConfigIndex, 1);
        }
      }
    });
    if (newMixedConfig?.length) {
      list = list.concat(newMixedConfig);
    }
    return list;
  };

  getGroupColumns(
    mixedSectionConfigRows: ChartDataSectionField[],
    tableHeader: TableHeaderConfig[],
    chartDataSet: IChartDataSet<string>,
    styleConfigs: ChartStyleConfig[],
    viewMeasureRelation?: MeasureRelationModel[],
    measureEnable?: string,
  ): TableColumnsList[] {
    
    const dataConfigs = this.getGroupColumnsOfFlattenedColumns(
      tableHeader,
      mixedSectionConfigRows,
      chartDataSet,
    );

    const flattenedColumns = this.getFlatColumns(
      dataConfigs,
      chartDataSet,
      styleConfigs,
      viewMeasureRelation,
      measureEnable,
    );
    const groupedHeaderColumns: TableColumnsList[] =
      tableHeader
        ?.map(
          style =>
            this.getHeaderColumnGroup(chartDataSet, style, flattenedColumns) ||
            [],
        )
        ?.filter(Boolean) || [];
    const unusedHeaderRows: TableColumnsList[] = getUnusedHeaderRows(
      flattenedColumns,
      groupedHeaderColumns,
    );


    return groupedHeaderColumns.concat(unusedHeaderRows);
  }

  private getHeaderColumnGroup(
    chartDataSet: IChartDataSet<string>,
    tableHeader: TableHeaderConfig,
    columns: TableColumnsList[],
  ): TableColumnsList {

    if (!tableHeader.isGroup) {
      const column = columns.find(
        c => c.key === chartDataSet.getFieldKey(tableHeader),
      );
      return column!;
    }
    return {
      uid: tableHeader.uid,
      colName: tableHeader?.colName,
      title: tableHeader.label,
      label: tableHeader.label,
      isGroup: tableHeader.isGroup,
      onHeaderCell: record => {
        return {
          ...Omit(record, ['dataIndex', 'onHeaderCell', 'onCell', 'colName']),
        };
      },
      children: (tableHeader.children || [])
        .map(th => {
          return this.getHeaderColumnGroup(chartDataSet, th, columns);
        })
        .filter(Boolean),
    };
  }

  protected getAntdTableStyleOptions(
    styleConfigs?: ChartStyleConfig[],
    settingConfigs?: ChartStyleConfig[],
    context?: ChartContext,
  ): TableStyleOptions {
    const [enablePaging] = getStyles(
      settingConfigs || [],
      ['paging'],
      ['enablePaging'],
    );
    const [showTableBorder, enableFixedHeader] = getStyles(
      styleConfigs || [],
      ['style'],
      ['enableBorder', 'enableFixedHeader'],
    );
    const [tableHeaderStyles] = getStyles(
      styleConfigs || [],
      ['header', 'modal'],
      ['tableHeaders'],
    );

    const [font] = getStyles(
      styleConfigs || [],
      ['tableHeaderStyle'],
      ['font'],
    );
    const [summaryFont] = getStyles(
      settingConfigs || [],
      ['summary'],
      ['summaryFont', 'summaryAlign'],
    );
    const [tableSize, scrollX] = getStyles(styleConfigs || [], ['style'], ['tableSize', 'scrollX']);
    const HEADER_PADDING = { default: 16, middle: 24, small: 16 };
    const TABLE_LINE_HEIGHT = 1;
    const PAGINATION_HEIGHT = { default: 48, middle: 56, small: 48 };
    const SUMMRAY_ROW_HEIGHT = { default: 18, middle: 26, small: 18 };
    const _getMaxHeaderHierarchy = (headerStyles: Array<{ children: [] }>) => {
      const _maxDeeps = (arr: Array<{ children: [] }> = [], deeps: number) => {
        if (!isEmptyArray(arr) && arr?.length > 0) {
          return Math.max(...arr.map(a => _maxDeeps(a.children, deeps + 1)));
        }
        return deeps;
      };
      return _maxDeeps(headerStyles, 0) || 1;
    };
    const headerHeight =
      ((font?.fontSize || 0) * TABLE_LINE_HEIGHT +
        HEADER_PADDING[tableSize || 'default'] +
        (showTableBorder ? this.tableCellBorder : 0)) *
      _getMaxHeaderHierarchy(tableHeaderStyles) +
      this.tableCellBorder;

    const _scrollX = scrollX === "true" ? true : scrollX
    return {
      scroll: Object.assign({
        scrollToFirstRowOnChange: true,
        x: _scrollX ? _scrollX : !enableFixedHeader
          ? '100%'
          : this.exceedMaxContent
            ? this.totalWidth
            : '100%',
        y: !enableFixedHeader
          ? '100%'
          : context?.height
            ? context?.height -
            10 -
            (this.showSummaryRow
              ? SUMMRAY_ROW_HEIGHT[tableSize || 'default'] +
              (summaryFont?.fontSize || 0) * TABLE_LINE_HEIGHT
              : 0) -
            headerHeight -
            (enablePaging ? PAGINATION_HEIGHT[tableSize || 'default'] : 0)
            : 0,
      }),
      bordered: !!showTableBorder,
      size: tableSize || 'default',
    };
  }

  protected getPagingOptions(
    settingConfigs: ChartStyleConfig[],
    pageInfo?: Partial<PageInfo>,
  ): PageOptions {
    const [enablePaging] = getStyles(
      settingConfigs,
      ['paging'],
      ['enablePaging'],
    );
    this.pageInfo = pageInfo;
    return enablePaging
      ? Object.assign({
        showSizeChanger: false,
        current: pageInfo?.pageNo,
        pageSize: pageInfo?.pageSize,
        total: pageInfo?.total,
      })
      : false;
  }

  private createEventParams = params => ({
    type: 'click',
    componentType: 'table',
    seriesType: undefined,
    data: undefined,
    dataIndex: undefined,
    event: undefined,
    name: undefined,
    seriesName: undefined,
    value: undefined,
    ...params,
  });

  private invokePagingRelatedEvents(
    seriesName: string,
    value: any,
    pageNo: number,
    aggOperator?: string,
  ) {
    const eventParams = this.createEventParams({
      seriesType: 'paging-sort-filter',
      seriesName,
      value: {
        aggOperator: aggOperator,
        direction:
          value === undefined ? undefined : value === 'ascend' ? 'ASC' : 'DESC',
        pageNo,
      },
    });
    this.mouseEvents?.forEach(cur => {
      if (cur.name === 'click') {
        cur.callback?.(eventParams);
      }
    });
  }

  private registerTableCellEvents(
    seriesName: string,
    value: any,
    dataIndex: number,
    rowData: any,
    aggOperator?: string,
  ): TableCellEvents {
    const eventParams = this.createEventParams({
      seriesType: 'body',
      name: seriesName,
      data: {
        format: undefined,
        name: seriesName,
        aggOperator,
        rowData,
        value: value,
      },
      seriesName, // column name/index
      dataIndex, // row index
      value, // cell value
    });
    return this.mouseEvents?.reduce((acc, cur) => {
      cur.name && (eventParams.type = cur.name);
      if (cur.name === 'click') {
        Object.assign(acc, {
          onClick: event => cur.callback?.({ ...eventParams, event }),
        });
      }
      if (cur.name === 'dblclick') {
        Object.assign(acc, {
          onDoubleClick: event => cur.callback?.({ ...eventParams, event }),
        });
      }
      if (cur.name === 'contextmenu') {
        Object.assign(acc, {
          onContextMenu: event => cur.callback?.({ ...eventParams, event }),
        });
      }
      if (cur.name === 'mouseover') {
        Object.assign(acc, {
          onMouseEnter: event => cur.callback?.({ ...eventParams, event }),
        });
      }
      if (cur.name === 'mouseout') {
        Object.assign(acc, {
          onMouseLeave: event => cur.callback?.({ ...eventParams, event }),
        });
      }
      return acc;
    }, {});
  }

  private getTextWidth = (
    context: ChartContext,
    text: string,
    fontWeight: string,
    fontSize: string,
    fontFamily: string,
  ): number => {
    // if(text && typeof text === "string" && text?.includes("\n")) {
    //   return Math.max(...text?.split("\n").map(item => getTextWidth(item, fontWeight, fontSize, fontFamily)))
    // }
    const canvas =
      this.utilCanvas ||
      (this.utilCanvas = context.document.createElement('canvas'));
    const measureLayer = canvas.getContext('2d');
    measureLayer.font = `${fontWeight} ${fontSize}px ${fontFamily}`;
    const metrics = measureLayer.measureText(this.convertExpressionTitles(text));
    if (text && this.isValidHttpUrl(text)) {
      const split = text.split(";");
      return split.length * 55;
    }
    return Math.ceil(metrics.width);
  };

  private findHeader = (
    uid: string | undefined,
    headers: TableHeaderConfig[],
  ): TableHeaderConfig | undefined => {
    let header = (headers || [])
      .filter(h => !h.isGroup)
      .find(h => h.uid === uid);
    if (!!header) {
      return header;
    }
    for (let i = 0; i < (headers || []).length; i++) {
      header = this.findHeader(uid, headers[i].children || []);
      if (!!header) {
        break;
      }
    }
    return header;
  };
  setBackendChartId = (backendChartId: string | undefined) => {
    // eslint-disable-next-line no-self-assign
    backendChartId = backendChartId;
  };

  private isValidHttpUrl = (value: string): boolean => {
    let url;

    try {
      url = new URL(value);
    } catch (_) {
      return false;
    }

    return url.protocol === 'http:' || url.protocol === 'https:';
  }
}

export default BasicTableChart;
