/**
 * Datart
 *
 * Copyright 2021
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { ChartDataSectionType } from 'app/constants';
import {
  ChartConfig,
  ChartDataSectionField,
  ChartStyleConfig,
  LabelStyle,
  LegendStyle,
  LineStyle,
  SeriesStyle,
} from 'app/types/ChartConfig';
import ChartDataSetDTO, { IChartDataSet } from 'app/types/ChartDataSet';
import {
  getAxisLabel,
  getAxisLine,
  getAxisTick,
  getColumnRenderName,
  getExtraSeriesDataFormat,
  getExtraSeriesRowData,
  getGridStyle,
  getReference2,
  getSeriesTooltips4Polar2,
  getSplitLine,
  getStyles,
  hadAxisLabelOverflowConfig,
  setOptionsByAxisLabelOverflow,
  toFormattedValue,
  transformToDataSet,
} from 'app/utils/chartHelper';
import { init } from 'echarts';
import Chart from '../../../models/Chart';
import Config from './config';
import { DoubleYChartXAxis, DoubleYChartYAxis, Series } from './types';

class BasicDoubleYChart extends Chart {
  dependency = [];
  config = Config;
  chart: any = null;

  constructor() {
    super('double-y', 'chartName', 'fsux_tubiao_shuangzhoutu');
    this.meta.requirements = [
      {
        group: 1,
        aggregate: [2, 999],
      },
    ];
  }

  onMount(options, context): void {
    if (options.containerId === undefined || !context.document) {
      return;
    }

    this.chart = init(
      context.document.getElementById(options.containerId),
      'default',
    );
    this.mouseEvents?.forEach(event => {
      this.chart.on(event.name, event.callback);
    });
  }

  onUpdated(props): void {
    if (!props.dataset || !props.dataset.columns || !props.config) {
      return;
    }
    if (!this.isMatchRequirement(props.config)) {
      return this.chart?.clear();
    }
    const newOptions = this.getOptions(props.dataset, props.config);
    this.chart?.setOption(Object.assign({}, newOptions), true);
  }

  onUnMount(): void {
    this.chart?.dispose();
  }

  onResize(opt: any, context): void {
    this.chart?.resize(context);
    hadAxisLabelOverflowConfig(this.chart?.getOption()) && this.onUpdated(opt);
  }

  private getOptions(dataset: ChartDataSetDTO, config: ChartConfig) {
    const dataConfigs = config.datas || [];
    const styleConfigs = config.styles || [];
    const settingConfigs = config.settings || [];

    const chartDataSet = transformToDataSet(
      dataset.rows,
      dataset.columns,
      dataConfigs,
    );

    console.log("chartDataSet: ", chartDataSet)
    const groupConfigs = dataConfigs
      .filter(c => c.type === ChartDataSectionType.GROUP)
      .flatMap(config => config.rows || []);
    const infoConfigs = dataConfigs
      .filter(c => c.type === ChartDataSectionType.INFO)
      .flatMap(config => config.rows || []);
    const colorConfigs: ChartDataSectionField[] = dataConfigs
        .filter(c => c.type === ChartDataSectionType.COLOR)
        .flatMap(config => config.rows || []);

    const leftMetricsConfigs = dataConfigs
      .filter(
        c => c.type === ChartDataSectionType.AGGREGATE && c.key === 'metricsL',
      )
      .flatMap(config => config.rows || []);
    const rightMetricsConfigs = dataConfigs
      .filter(
        c => c.type === ChartDataSectionType.AGGREGATE && c.key === 'metricsR',
      )
      .flatMap(config => config.rows || []);

    if (!leftMetricsConfigs.concat(rightMetricsConfigs)?.length) {
      return {};
    }

    const yAxisNames: string[] = leftMetricsConfigs
      .concat(rightMetricsConfigs)
      .map(getColumnRenderName);

    // @TM 溢出自动根据bar长度设置标尺
    const option = setOptionsByAxisLabelOverflow({
      chart: this.chart,
      xAxis: this.getXAxis(styleConfigs, groupConfigs, chartDataSet),
      yAxis: this.getYAxis(
        styleConfigs,
        leftMetricsConfigs,
        rightMetricsConfigs,
        chartDataSet
      ),
      grid: getGridStyle(styleConfigs),
      series: this.getSeries(
        styleConfigs,
        settingConfigs,
        colorConfigs,
        leftMetricsConfigs,
        rightMetricsConfigs,
        chartDataSet,
      ),
      yAxisNames,
    });

    console.log("option: ", option.series)

    let [tooltipFont] = getStyles(styleConfigs,['label'],["tooltipFont"]);
    let [tooltipBackgroundColor] = getStyles(styleConfigs,['label'],["backgroundColor"]);

    return {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'none',
        },
        backgroundColor: tooltipBackgroundColor,
        textStyle: {
          ...tooltipFont
        },
        formatter: this.getTooltipFormmaterFunc(
          styleConfigs,
          groupConfigs,
          leftMetricsConfigs.concat(rightMetricsConfigs),
          [],
          infoConfigs,
          chartDataSet,
        ),
      },
      legend: this.getLegend(styleConfigs, yAxisNames),
      ...option,
    };
  }

  private getSeries(
    styles: ChartStyleConfig[],
    settingConfigs: ChartStyleConfig[],
    colorConfigs: ChartDataSectionField[],
    leftDeminsionConfigs,
    rightDeminsionConfigs,
    chartDataSet: IChartDataSet<string>,
  ): Series[] {
    console.log("c1111111olorConfigs: ", colorConfigs)
    const _getSeriesByDemisionPostion =
      () =>
      (
        config: ChartDataSectionField,
        styles: ChartStyleConfig[],
        settings: ChartStyleConfig[],
        data: IChartDataSet<string>,
        direction: string,
        yAxisIndex: number,
      ): Series => {
        const [graphType, graphStyle] = getStyles(
          styles,
          [direction],
          ['graphType', 'graphStyle'],
        );
        return {
          yAxisIndex,
          name: getColumnRenderName(config),
          type: graphType || 'line',
          sampling: 'average',
          data: chartDataSet.map(dc => ({
            ...config,
            ...getExtraSeriesRowData(dc),
            ...getExtraSeriesDataFormat(config?.format),
            value: dc.getCell(config),
            itemStyle: {
              color: colorConfigs?.[0]?.color?.colors?.find(
                  c => {
                    return c.key === getExtraSeriesRowData(dc).rowData[colorConfigs?.[0].colName];
                  },
              )?.value,
            },
          })),
          ...this.getItemStyle(config, styles),
          ...this.getGraphStyle(graphType, graphStyle),
          ...this.getLabelStyle(styles, direction),
          ...this.getSeriesStyle(styles, direction),
          ...getReference2(settings, data, config, false),
        };
      };

    const series = []
      .concat(
        leftDeminsionConfigs.map(lc =>
          _getSeriesByDemisionPostion()(
            lc,
            styles,
            settingConfigs,
            chartDataSet,
            'leftY',
            0,
          ),
        ),
      )
      .concat(
        rightDeminsionConfigs.map(rc =>
          _getSeriesByDemisionPostion()(
            rc,
            styles,
            settingConfigs,
            chartDataSet,
            'rightY',
            1,
          ),
        ),
      );
    return series;
  }

  private getItemStyle(config, styles): { itemStyle: { color: string | undefined, borderRadius: number | undefined } } {
    const color = config?.color?.start;
    const [borderStyle, borderRadius] = getStyles(
      styles,
      ['graph'],
      ['borderStyle', 'radius'],
    );
    return {
      itemStyle: {
        color,
        borderRadius,
      },
    };
  }

  private getGraphStyle(
    graphType,
    style,
  ): { lineStyle?: LineStyle; barWidth?: string; color?: string } {
    if (graphType === 'line') {
      return { lineStyle: style };
    } else {
      return {
        barWidth: style?.width,
        color: style?.color,
      };
    }
  }

  private getXAxis(
    styles: ChartStyleConfig[],
    xAxisConfigs: ChartDataSectionField[],
    chartDataSet: IChartDataSet<string>,
  ): DoubleYChartXAxis {
    const fisrtXAxisConfig = xAxisConfigs[0];
    let [
      showAxis,
      inverse,
      lineStyle,
      showLabel,
      font,
      rotate,
      showInterval,
      interval,
      overflow,
      offset,
      formatter,
    ] = getStyles(
      styles,
      ['xAxis'],
      [
        'showAxis',
        'inverseAxis',
        'lineStyle',
        'showLabel',
        'font',
        'rotate',
        'showInterval',
        'interval',
        'overflow',
        'offset',
        'formatter',
      ],
    );
    const [showVerticalLine, verticalLineStyle] = getStyles(
      styles,
      ['splitLine'],
      ['showVerticalLine', 'verticalLineStyle'],
    );

    return {
      type: 'category',
      tooltip: { show: true },
      inverse,
      axisLabel: getAxisLabel(
        showLabel,
        font,
        showInterval ? interval : null,
        rotate,
        overflow,
        formatter
      ),
      axisLine: getAxisLine(showAxis, lineStyle),
      axisTick: getAxisTick(showLabel, lineStyle),
      splitLine: getSplitLine(showVerticalLine, verticalLineStyle),
      data: chartDataSet.map(d => d.getCell(fisrtXAxisConfig)),
      offset
    };
  }

  private getYAxis(
    styles: ChartStyleConfig[],
    leftDeminsionConfigs: ChartDataSectionField[],
    rightDeminsionConfigs: ChartDataSectionField[],
    chartDataSet: IChartDataSet<string>,
  ): DoubleYChartYAxis[] {
    const [showHorizonLine, horizonLineStyle] = getStyles(
      styles,
      ['splitLine'],
      ['showHorizonLine', 'horizonLineStyle'],
    );

    const leftYData = leftDeminsionConfigs.map(item => {
      return chartDataSet.map(d => d.getCell(item))
    }).reduce((oldArray, newArray) => {
      return oldArray.concat(newArray)
    }).map(item => parseFloat(item))

    const rightYData = rightDeminsionConfigs.map(item => {
      return chartDataSet.map(d => d.getCell(item))
    }).reduce((oldArray, newArray) => {
      return oldArray.concat(newArray)
    }).map(item => parseFloat(item))

    const leftMax = Math.max(...leftYData);
    const leftMin = Math.min(...leftYData);

    const rightMax = Math.max(...rightYData);
    const rightMin = Math.min(...rightYData);

    const _yAxisTemplate = (position, name): DoubleYChartYAxis => {
      let [showAxis, inverse, showName, font, showLabel, max, min] = getStyles(
        styles,
        [`${position}Y`],
        ['showAxis', 'inverseAxis', 'showName', 'font', 'showLabel', 'max', 'min'],
      );

      let _maxVal = 0;
      let _minVal = 0;

      _maxVal = (max !== undefined && max != null) ? max : position === 'left' ? leftMax : rightMax;
      _minVal = (min !== undefined && min != null) ? min : position === 'left' ? leftMin : rightMin;

      let interval = 0;

      if (_minVal >= -1 && _minVal < 0 && _maxVal >= 0 && _maxVal <= 1) {
        _minVal = (min !== undefined && min != null) ? min : -1;
        _maxVal = (max !== undefined && max != null) ? max : 1;
        interval = Number(((_maxVal - _minVal) / 5).toFixed(2));
      } else if (_minVal >= 0 && _minVal <= 1 && _maxVal >= 0 && _maxVal <= 1) {
        _minVal = (min !== undefined && min != null) ? min : 0;
        _maxVal = (max !== undefined && max != null) ? max : 1;
        interval = Number(((_maxVal - _minVal) / 5).toFixed(2));
      } else if (_minVal >= -1 && _minVal < 0 && _maxVal >= -1 && _maxVal <= 0) {
        _minVal = (min !== undefined && min != null) ? min : -1;
        _maxVal = (max !== undefined && max != null) ? max : 0;
        interval = Number(((_maxVal - _minVal) / 5).toFixed(2));
      } else {
        _minVal = Math.floor(_minVal * 1 * 0.01) * 100;

        interval = Math.ceil((_maxVal - _minVal) / 5) == 0 ? 1 : Math.ceil((_maxVal - _minVal) / 5);
        interval = interval < 10 ? interval : this.up2FiveOrZero(interval);
      }

      return {
        type: 'value',
        position,
        showTitleAndUnit: true,
        name: showName ? name : '',
        nameLocation: 'middle',
        max: Number((_minVal + interval * 5).toFixed(2)),
        min: _minVal,
        nameGap: 50,
        nameRotate: 90,
        nameTextStyle: {
          color: '#666',
          fontFamily: 'PingFang SC',
          fontSize: 12,
        },
        interval,
        inverse,
        axisLine: getAxisLine(showAxis),
        axisLabel: getAxisLabel(showLabel, font),
        splitLine: getSplitLine(showHorizonLine, horizonLineStyle),
      };
    };

    const leftYAxisNames = leftDeminsionConfigs
      .map(getColumnRenderName)
      .join('/');
    const rightYAxisNames = rightDeminsionConfigs
      .map(getColumnRenderName)
      .join('/');

    return [
      _yAxisTemplate('left', leftYAxisNames),
      _yAxisTemplate('right', rightYAxisNames),
    ];
  }

  private getLegend(styles, seriesNames): LegendStyle {
    const [show, type, font, legendPos, selectAll, left] = getStyles(
      styles,
      ['legend'],
      ['showLegend', 'type', 'font', 'position', 'selectAll', 'left'],
    );
    let positions = {};
    let orient = '';

    let leftVal = left ? (left === 'left' ? 18 : left) : 8;

    switch (legendPos) {
      case 'top':
        orient = 'horizontal';
        positions = { top: 8, left: leftVal, right: 8, height: 32 };
        break;
      case 'bottom':
        orient = 'horizontal';
        positions = { bottom: 8, left: leftVal, right: 8, height: 32 };
        break;
      case 'left':
        orient = 'vertical';
        positions = { left: 8, top: leftVal, bottom: 24, width: 96 };
        break;
      default:
        orient = 'vertical';
        positions = { right: 8, top: leftVal, bottom: 24, width: 96 };
        break;
    }
    const selected: { [x: string]: boolean } = seriesNames.reduce(
      (obj, name) => ({
        ...obj,
        [name]: selectAll,
      }),
      {},
    );

    return {
      ...positions,
      show,
      type,
      orient,
      selected,
      data: seriesNames,
      textStyle: font,
    };
  }

  private getLabelStyle(
    styles: ChartStyleConfig[],
    direction: string,
  ): LabelStyle {
    const [showLabel, position, LabelFont] = getStyles(
      styles,
      [direction + 'Label'],
      ['showLabel', 'position', 'font'],
    );

    return {
      label: {
        show: showLabel,
        position,
        ...LabelFont,
        formatter: params => {
          const { value, data } = params;
          const formattedValue = toFormattedValue(value, data.format);
          const labels: string[] = [];
          labels.push(formattedValue);
          return labels.join('\n');
        },
      },
    };
  }

  private getTooltipFormmaterFunc(
    styleConfigs: ChartStyleConfig[],
    groupConfigs: ChartDataSectionField[],
    aggregateConfigs: ChartDataSectionField[],
    colorConfigs: ChartDataSectionField[],
    infoConfigs: ChartDataSectionField[],
    chartDataSet: IChartDataSet<string>,
  ): (params) => string {
    return seriesParams => {
      return getSeriesTooltips4Polar2(
        chartDataSet,
        seriesParams[0],
        groupConfigs,
        colorConfigs,
        aggregateConfigs,
        infoConfigs,
      );
    };
  }

  private getSeriesStyle(styles: ChartStyleConfig[], direction: string): SeriesStyle {
    const [smooth, stack, step, symbol] = getStyles(
      styles,
      ['graph'],
      ['smooth', 'stack', 'step', 'symbol'],
    );
    const [showLabel] = getStyles(
      styles,
      [direction + 'Label'],
      ['showLabel'],
    );
    // TODO
    // 后续symbol做成可配置需要改逻辑
    const showSymbol = showLabel;
    return { smooth, step, symbol: showSymbol ? 'emptyCircle' : 'none', stack };
  }

  private up2FiveOrZero(val: number) {
    let num = val % 10;
    return num <= 5 ? Math.floor(val * 1 * 0.1) * 10 + 5 : Math.ceil(val * 1 * 0.1) * 10
  }
}

export default BasicDoubleYChart;
